/* eslint-disable no-unused-vars, array-callback-return, react/no-unescaped-entities */
import {
  Button,
  Col,
  Form,
  Row,
  Carousel,
  Image,
} from "@themesberg/react-bootstrap";
import { useFormik } from "formik";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import TimePicker from "react-time-picker";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import profile from "../../../assets/img/upload.png";
import { format } from "date-fns";
// import AddCategory from "../../components/addCategory";
import {
  addWorkerFormJob,
  jobById,
  updateJob,
} from "../../../Redux/addJob/actions";
import {
  getCategoryList,
  getSingleList,
} from "../../../Redux/Category/actions";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const GeneralInfoForm = () => {
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
  const params = useLocation();
  let id = params.pathname.split("/")[2];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subCategoryData = useSelector(
    (state) => state?.Category?.SingleList?.sub_Categories
  );
  console.log(id, "id");
  // const activeForm = params?.location?.state;
  const CategoryData = useSelector((state) => state?.Category?.getCategoryList);
  const SingleId = useSelector((state) => (id ? state?.addJob?.jobById : null));
  let str =
    SingleId?.category?.length > 0 ? SingleId?.category[0]?.category : "false";
  const [selectedImage, setSelectedImage] = useState([]);
  const [hours, setHours] = useState("1");
  const [days, setDays] = useState("1");
  const [jobType, setJobType] = useState(
    SingleId?.jobType?.name ? SingleId?.jobType?.name : "Part-time"
  );
  const [jobPlace, setJobPlace] = useState(
    SingleId?.jobPlace ? SingleId?.jobPlace : "Business Location"
  );
  const [paymentType, setPaymentType] = useState(
    SingleId?.paymentType ? SingleId?.paymentType : "hourly"
  );
  const [categories, setCategories] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  const [location, setLocation] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [rate, setRate] = useState("");
  const [longitude, setLongitude] = useState();
  const [latitude, setLatitude] = useState();
  const [startDate, setStartDate] = React.useState(new Date());
  const [startTime, setStartTime] = useState("12:00");
  const [endTime, setEndTime] = useState("12:00");
  const [endDate, setEndDate] = React.useState(new Date());
  const [postJob, setPostJob] = useState(false);
  const [activeButton, setActiveButton] = useState("");
  const [buttonDisable, setButtonDisabled] = useState(false);
  const [loader, setLoader] = useState(false);
  let jobId = params.pathname.split("/")[2];

  const iconStyle = {
    backgroundColor: "black",
    width: "50px",
    borderRadius: "50%", // Optional: make the background circular
    padding: "5px", // Optional: add some padding
    color: "white", // Icon color
    cursor: "pointer", // Change cursor to pointer
  };

  useEffect(() => {
    setPaymentType(SingleId?.paymentType ? SingleId?.paymentType : "hourly");
    setJobType(SingleId?.jobType?.name ? SingleId?.jobType?.name : "Part-time");
    setJobPlace(SingleId?.jobPlace ? SingleId?.jobPlace : "Business Location");
    setRate(SingleId?.rate ? SingleId?.rate : "");
    setLocation(SingleId?.location ? SingleId?.location[0] : []);
    setLatitude(SingleId?.latitude ? SingleId?.latitude : "");
    setLongitude(SingleId?.longitude ? SingleId?.longitude : "");
    setStartDate(
      SingleId?.startDate ? new Date(SingleId?.startDate) : new Date()
    );
    setEndDate(SingleId?.endDtae ? new Date(SingleId?.endDtae) : new Date());
    setStartTime(SingleId?.startTime ? SingleId?.startTime : "");
    setSelectedImage(SingleId?.images ? SingleId?.images : []);
    setEndTime(SingleId?.endTime ? SingleId?.endTime : "");
  }, [SingleId, str.details]);

  useEffect(() => {
    if (categories?.value[0].id !== undefined) {
      dispatch(getSingleList({ id: categories?.value[0].id, search: "" }));
    }
  }, [categories, dispatch]);

  useEffect(() => {
    dispatch(getCategoryList({ search: "", setLoader: setLoader }));
  }, [dispatch]);

  useEffect(() => {
    let array = [];
    subCategoryData?.map((item) => {
      array.push({
        value: [{ id: item?.id, title: item?.title, details: item?.details }],
        label: item?.title,
      });
    });
    setSubCategoryList(array);
  }, [subCategoryData]);

  useEffect(() => {
    let array = [];
    CategoryData?.map((item) => {
      array.push({
        value: [{ id: item?.id, title: item?.title, details: item?.details }],
        label: item?.title,
      });
    });
    setCategoryList(array);
  }, [CategoryData]);

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.click();
  };
  const CategorySchema = Yup.object().shape({
    jobName: Yup.string().trim().required("Job Name is required"),
    description: Yup.string().trim().required("description is required"),
    rate: Yup.number() // Change to number validation
      .required("Rate is required")
      .positive("Rate must be a positive number") // Ensure it's positive
      .min(1, "Rate should not be less than 1"),
  });

  const CategoryFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: SingleId?.id ? SingleId?.id : "",
      jobName: SingleId?.name ? SingleId?.name : "",
      description: SingleId?.description ? SingleId?.description : "",
      rate: SingleId?.rate ? SingleId?.rate : "--",
      jobType: SingleId?.jobType ? SingleId?.jobType : "",
      jobPlace: SingleId?.jobPlace ? SingleId?.jobPlace : "",
      paymentType: SingleId?.paymentType ? SingleId?.paymentType : "",
      isPost: SingleId?.isPost ? SingleId?.isPost : "",
      startDate: SingleId?.startDate ? SingleId?.startDate : "",
      startTime: SingleId?.startTime ? SingleId?.startTime : "",
      endTime: SingleId?.endTime ? SingleId?.endTime : "",
      endDate: SingleId?.endDate ? SingleId?.endDate : null,
      location: SingleId?.location ? SingleId?.location : "",
      latitude: SingleId?.latitude ? SingleId?.latitude : "",
      days: SingleId?.days ? SingleId?.days : "",
      hours: SingleId?.hours ? SingleId?.hours : "",
      jobImg: SingleId?.image ? SingleId?.image : "",
      providers: SingleId?.noOfProviders ? SingleId?.noOfProviders : "",
      remember: true,
      category: SingleId?.category ? SingleId?.category : "",
      subCategory: SingleId?.subCategory ? SingleId?.subCategory : "",
    },
    validationSchema: CategorySchema,
    onSubmit: async (values, action) => {
      if (
        !startTime ||
        !endTime ||
        location === "" ||
        !location ||
        selectedImage.length === 0 ||
        !subCategories
      ) {
        return;
      }
      setPostJob(true);
      const formattedStartDate = `"${format(
        startDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      const formattedEndDate = `"${format(
        endDate,
        "yyyy-MM-dd'T'HH:mm:ssxxx"
      )}"`;
      let nestedArray = location ? [location] : [];

      // Flatten the nested array into a single-level array
      let flattenedArray = nestedArray.flat(Infinity);
      let data = {
        id: values.id,
        name: values.jobName,
        description: values.description,
        rate: values.rate,
        unit: values.unit,
        jobType: jobType,
        jobPlace: jobPlace,
        paymentType: paymentType,
        category: subCategories.value[0].id,
        days: days,
        hours: hours,
        location: flattenedArray,
        latitude: latitude,
        longitude: longitude,
        setReset: action.resetForm,
        startTime: JSON.stringify(startTime),
        endTime: JSON.stringify(endTime),
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        jobImg: selectedImage ? selectedImage : null,
        image: SingleId?.image ? SingleId?.image : null,
        setSelectedImage: setSelectedImage,
        history: navigate,
        existImg: SingleId?.image,
        setPostJob: setPostJob,
        setButtonDisabled: setButtonDisabled,
        serviceId: jobId,
      };

      if (!id) {
        dispatch(addWorkerFormJob(data));
      } else {
        if (activeButton === "update") {
          dispatch(updateJob(data, id));
        }
      }
    },
  });

  const handleInputChange = (e) => {
    setLocation(e.target.value);
  };
  useEffect(() => {
    if (jobId !== undefined) {
      dispatch(jobById({ id: jobId, setLoader: setLoader }));
    }
  }, [jobId, dispatch]);

  const imageChange = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) => ({
        file: file,
        preview: URL.createObjectURL(file),
      }));
      setSelectedImage(filesArray); // Update to handle multiple images
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
      setLongitude(place.geometry.location.lng());
      setLatitude(place.geometry.location.lat());
    },
    options: {
      componentRestrictions: {},
      types: ["address"],
      fields: [
        "address_components",
        "formatted_address",
        "geometry",
        "name",
        "place_id",
      ],
    },
    defaultValue: location,
  });

  const PreventFirstZero = (e) => {
    if (e.target.value.length === 0 && e.which === "0".charCodeAt(0)) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <>
      <Col className={"d-flex justify-content-center"}>
        <Carousel
          controls={selectedImage?.length > 1}
          interval={null}
          style={{ width: "150px" }}
          indicators={false}
          prevIcon={
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
          }
          nextIcon={
            <div style={iconStyle}>
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          } // Use styled icons
        >
          {selectedImage?.length > 0 ? (
            selectedImage.map(
              (
                item,
                index // Map through multiple images
              ) => (
                <Carousel.Item key={index}>
                  <Image
                    src={item.name ?? URL.createObjectURL(item.file)}
                    onClick={onButtonClick}
                    style={{
                      width: "150px",
                      height: "150px",
                      objectFit: "cover",
                    }}
                    className="navbar-brand-light"
                  />
                </Carousel.Item>
              )
            )
          ) : (
            <Carousel.Item>
              <img
                src={SingleId?.image ? SingleId.image : profile}
                alt="60px"
                width={"130px"}
                onClick={onButtonClick}
                style={{ cursor: "pointer", transition: "transform 0.5s ease" }}
              />
            </Carousel.Item>
          )}
        </Carousel>
        {CategoryFormik.touched.jobImg && selectedImage.length === 0 && (
          <div style={{ color: "red" }}>Image is Required</div>
        )}
        <Form.Control
          accept="image/*"
          type="file"
          id="file"
          name="file"
          onChange={imageChange}
          className="d-none"
          ref={inputEl}
          multiple
        />
      </Col>
      {/* <Col className="d-flex justify-content-center">
        <input
          className="d-none"
          type="file"
          accept="image/*"
          onChange={imageChange}
          ref={inputEl}
        />
        <Button
          onClick={onButtonClick}
          variant="secondary"
          size="sm"
          className="m-2"
        >
          <svg
            className="icon icon-xs me-2"
            fill="none"
            stroke="currentColor"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 11.487l2.12-2.122M21 3l-4.8 4.8a2 2 0 00-.546.874l-.94 3.763a.25.25 0 00.308.308l3.762-.94a2 2 0 00.874-.546L21 3z"
            ></path>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5 20h14a2 2 0 002-2v-7a2 2 0 00-2-2h-9l-4-4H5a2 2 0 00-2 2v11a2 2 0 002 2z"
            ></path>
          </svg>
          Change Photo
        </Button>
      </Col> */}
      <div className="mt-5">
        <Form
          onSubmit={CategoryFormik.handleSubmit}
          encType="multipart/form-data"
        >
          <Row>
            <Col>
              <Form.Group id="firstName" className="mb-4">
                <Form.Label>Job Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your job name"
                  name="jobName"
                  value={CategoryFormik.values.jobName}
                  onChange={CategoryFormik.handleChange}
                  isInvalid={
                    CategoryFormik.touched.jobName &&
                    !!CategoryFormik.errors.jobName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.jobName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="col-6">
              <Form.Group id="lastName" className="mb-4">
                <Form.Label>Category</Form.Label>
                <Select
                  name="categories"
                  options={categoryList}
                  onChange={setCategories}
                  defaultValue={categories}
                  value={categories}
                  isInvalid={
                    CategoryFormik.touched.category &&
                    !!CategoryFormik.errors.category
                  }
                />
                {CategoryFormik.touched.category &&
                  categories?.label === undefined && (
                    <div style={{ color: "red" }}>Category is Required</div>
                  )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group id="subCategory" className="mb-4">
                <Form.Label>Sub Category</Form.Label>
                <Select
                  name="subCategories"
                  options={subCategoryList}
                  onChange={setSubCategories}
                  defaultValue={subCategories}
                  value={subCategories}
                  isInvalid={
                    CategoryFormik.touched.subCategory &&
                    !!CategoryFormik.errors.subCategory
                  }
                />
                {CategoryFormik.touched.subCategory && !subCategories && (
                  <div style={{ color: "red" }}>Sub Category is Required</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                value={location}
                ref={ref}
                onChange={handleInputChange}
                style={{ width: "100%" }}
              />
              {CategoryFormik.touched.location && location.length === 0 && (
                <div style={{ color: "red" }}>Location is required</div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group id="description" className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Enter job description"
                  name="description"
                  value={CategoryFormik.values.description}
                  onChange={CategoryFormik.handleChange}
                  isInvalid={
                    CategoryFormik.touched.description &&
                    !!CategoryFormik.errors.description
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.description}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group id="rate" className="mb-4">
                <Form.Label>Rate</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter rate"
                  name="rate"
                  value={CategoryFormik.values.rate}
                  onChange={CategoryFormik.handleChange}
                  onKeyPress={PreventFirstZero}
                  isInvalid={
                    CategoryFormik.touched.rate && !!CategoryFormik.errors.rate
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.rate}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group id="paymentType" className="mb-4">
                <Form.Label>Payment Type</Form.Label>
                <Select
                  name="paymentType"
                  options={[
                    { value: "hourly", label: "Hourly" },
                    { value: "fixed", label: "Fixed" },
                  ]}
                  onChange={(e) => setPaymentType(e.value)}
                  defaultValue={{ value: paymentType, label: paymentType }}
                  isInvalid={
                    CategoryFormik.touched.paymentType &&
                    !!CategoryFormik.errors.paymentType
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.paymentType}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group id="jobPlace" className="mb-4">
                <Form.Label>Job Place</Form.Label>
                <Select
                  name="jobPlace"
                  options={[
                    { value: "Business Location", label: "Business Location" },
                    { value: "Remote", label: "Remote" },
                  ]}
                  onChange={(e) => setJobPlace(e.value)}
                  defaultValue={{ value: jobPlace, label: jobPlace }}
                  isInvalid={
                    CategoryFormik.touched.jobPlace &&
                    !!CategoryFormik.errors.jobPlace
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {CategoryFormik.errors.jobPlace}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group id="jobType" className="mb-4">
                <Form.Label>Job Type</Form.Label>
                <fieldset className="d-flex radioButton">
                  <Form.Check
                    // defaultChecked
                    type="radio"
                    checked={jobType === "Part-time"}
                    label="Part-time"
                    name="jobType"
                    value="Part-time"
                    className="radio1"
                    onChange={(event) => {
                      setJobType(event.target.value);
                    }}
                  />
                  <Form.Check
                    type="radio"
                    checked={jobType === "Permanent"}
                    label="Full-time"
                    name="jobType"
                    value="Permanent"
                    onChange={(event) => {
                      setJobType(event.target.value);
                    }}
                  />
                </fieldset>
              </Form.Group>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Form.Group id="days" className="mb-4">
                <Form.Label>Days</Form.Label>
                <Form.Control type="number" placeholder="Enter days" name="days" value={days} onChange={handledays} isInvalid={CategoryFormik.touched.days && !!CategoryFormik.errors.days} />
                <Form.Control.Feedback type="invalid">{CategoryFormik.errors.days}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group id="hours" className="mb-4">
                <Form.Label>Hours</Form.Label>
                <Form.Control type="number" placeholder="Enter hours" name="hours" value={hours} onChange={handleHours} isInvalid={CategoryFormik.touched.hours && !!CategoryFormik.errors.hours} />
                <Form.Control.Feedback type="invalid">{CategoryFormik.errors.hours}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row> */}
          <Row>
            <Col md={6} className="mb-1">
              <Form.Label>Start Time</Form.Label>
              <TimePicker
                disableClock={true}
                name="startTime"
                value={startTime}
                // format="HH:mm:ss"
                onChange={(newValue) => {
                  setStartTime(newValue);
                }}
              />
              {CategoryFormik.touched.startTime && !startTime && (
                <div style={{ color: "red" }}>Start Time is required</div>
              )}
            </Col>
            <Col md={6} className="mb-1">
              <Form.Label>End Time</Form.Label>
              <TimePicker
                disableClock={true}
                name="endTime"
                value={endTime}
                // format="HH:mm:ss"
                onChange={(newValue) => {
                  setEndTime(newValue);
                }}
              />
              {CategoryFormik.touched.endTime && !endTime && (
                <div style={{ color: "red" }}>End Time is required</div>
              )}
            </Col>
          </Row>
          <Box display={"flex"} justifyContent={"center"}>
            <Button
              variant="primary"
              type="submit"
              disabled={buttonDisable}
              onClick={() => setActiveButton(id ? "update" : "Post")}
            >
              {buttonDisable ? "Loading..." : id ? "Update" : "Post"}
            </Button>
          </Box>
        </Form>
      </div>
    </>
  );
};
