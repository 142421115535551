import React, { useState, useEffect, useCallback, useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { SendMessage, CustomOfferAccept } from "../../Redux/chat/actions";
import { deleteMessage } from "../Chat/FirestoreMethods";
import RenderBubble from "./renderBubble";
import ChatInputField from "./chatInputField";
import RenderDateSeparator from "./dateSeprator";
import axios from "axios";
import { BASE_URL_API } from "../../Routes/axiosConfig";

let jobOffer = {
  offeredPrice: 0,
  title: "",
  offerTo: "",
  offerDate: "",
  offerStatus: "",
};
let customKey = false;
let image = "";
const ChatboardNew = ({
  blockedBy,
  oneToOneChat,
  users,
  currentUser,
  id,
  zoom,
  zoomUrl,
}) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState("");
  const [groupedMessages, setGroupedMessages] = useState({});
  /* eslint-disable no-unused-vars */
  const [attachment, setAttachment] = useState("");
  const [attachmentObj, setAttachmentObj] = useState(null);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    if (oneToOneChat.length > 0) {
      setMessage("");

      const groupedMessages = oneToOneChat.reduce((acc, message) => {
        const messageDate = new Date(message.createdAt).toLocaleDateString(
          "en-US",
          {
            month: "short",
            day: "numeric",
            year: "numeric",
          }
        );
        acc[messageDate] = acc[messageDate] || [];
        acc[messageDate].push(message);
        return acc;
      }, {});

      // Update groupedMessages based on previous state
      setGroupedMessages(groupedMessages);
      removeAttachment();
    } else {
      setGroupedMessages({});
    }
  }, [oneToOneChat]);

  useEffect(() => {
    scrollToBottom();
  }, [groupedMessages]);

  useEffect(() => {
    if (zoomUrl) {
      onSend(zoomUrl, zoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoom, zoomUrl]);

  const onSend = useCallback(
    async (msg) => {
      if (attachmentObj) {
        await handleImageUpload(attachmentObj);
      }
      let data = {
        senderId: currentUser.id,
        receiverId: id,
        message: msg || zoomUrl,
        attachment: image,
      };
      let jobOffer = {};
      if (blockedBy === null) {
        setLoader(true);
        dispatch(
          SendMessage({
            data,
            message: msg,
            users,
            currentUser,
            customKey,
            zoom,
            jobOffer,
          })
        );
        setLoader(false);
      } else {
        toast.error(
          blockedBy
            ? "You have blocked this user"
            : "You have been blocked by this user"
        );
      }
      setMessage("");
      setAttachment("");
      setAttachmentObj(null);
    },
    [attachmentObj, blockedBy, currentUser, dispatch, id, users, zoom, zoomUrl]
  );

  const handleDelete = (id) => {
    deleteMessage(id, users);
  };

  const handleImageUpload = async (_attachment) => {
    setLoader(true);
    try {
      const formData = new FormData();
      formData.append("File", _attachment);

      const response = await axios.post(
        `${BASE_URL_API}chat/uploadImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // setAttachment(response.data.data.image);
      image = response.data.data.image;
      setMessage("Image Added");
      setLoader(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      setLoader(false);
    }
  };

  const handleAccept = useCallback(
    (data) => {
      jobOffer = {
        offeredPrice: data?.jobOffer?.offeredPrice || "",
        title: data?.jobOffer?.title || "",
        offerTo: data?.jobOffer?.offerTo || "",
        offerDays: data?.jobOffer?.offerDays || 0,
        offerHours: data?.jobOffer?.offerHours || 0,
        offerStatus: "Accepted",
        jobId: data?.jobId || "",
      };
      let newData = {
        jobOffer: jobOffer,
        users: users,
        jobId: data.jobOffer.jobId,
        userId: id,
        isAccepted: true,
        id: data.id,
      };
      if (blockedBy === null) {
        dispatch(CustomOfferAccept(newData));
      } else {
        toast.error(
          blockedBy
            ? "You have blocked this user"
            : "You have been blocked by this user"
        );
      }
    },
    [blockedBy, dispatch, users, id]
  );

  const handleDecline = useCallback(
    (data) => {
      jobOffer = {
        offeredPrice: data?.jobOffer?.offeredPrice || "",
        title: data?.jobOffer?.title || "",
        offerTo: data?.jobOffer?.title || "",
        offerDays: data?.jobOffer?.offerDays || 0,
        offerHours: data?.jobOffer?.offerHours || 0,
        offerStatus: "Rejected",
        jobId: data?.jobId || "",
      };
      let newData = {
        jobOffer: jobOffer,
        users: users,
        jobId: data.jobOffer.jobId,
        userId: currentUser?.id,
        isAccepted: false,
        id: data.id,
      };
      if (blockedBy === null) {
        dispatch(CustomOfferAccept(newData));
      } else {
        toast.error(
          blockedBy
            ? "You have blocked this user"
            : "You have been blocked by this user"
        );
      }
    },
    [blockedBy, currentUser?.id, dispatch, users]
  );

  const scrollToBottom = () => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"]; // Add more allowed types if needed
      if (allowedTypes.includes(file.type)) {
        setAttachmentObj(file);
      } else {
        toast.error("Please select a valid image file (JPEG, PNG, GIF)");
      }
    }
  };

  const removeAttachment = () => {
    setAttachment("");
    setAttachmentObj(null);
    image = "";
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
  };
  return (
    <>
      {groupedMessages && (
        <div className="messageContainer" ref={messageContainerRef}>
          {Object.entries(groupedMessages)
            .reverse()
            .map(([date, messages]) => (
              <React.Fragment key={date}>
                <RenderDateSeparator date={date} />
                {messages
                  .slice()
                  .reverse()
                  .map((message) => (
                    <div className="render-bubble" key={message.id}>
                      <RenderBubble
                        currentMessage={message}
                        currentUser={currentUser}
                        handleDelete={handleDelete}
                        handleAccept={handleAccept}
                        handleDecline={handleDecline}
                      />
                    </div>
                  ))}
              </React.Fragment>
            ))}
        </div>
      )}

      <ChatInputField
        blockedBy={blockedBy}
        setMessage={setMessage}
        message={message}
        enabled={!!message && !loader}
        onSend={() => onSend(message)}
      />
      {!blockedBy && (
        <label htmlFor="file-upload" className="file-upload-label">
          <svg
            className="svg-attachment"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#3461FD"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <circle cx="8.5" cy="8.5" r="1.5" />
            <polyline points="21 15 16 10 5 21" />
          </svg>
        </label>
      )}
      {attachmentObj && (
        <span>
          {attachmentObj.name} {" ---"}
          {attachmentObj.name && (
            <svg
              width="15"
              height="15"
              viewBox="0 0 19 19"
              fill="none"
              onClick={() => removeAttachment()}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.85938 3.26562V3.59375H11.1406V3.26562C11.1406 2.8305 10.9678 2.4132 10.6601 2.10553C10.3524 1.79785 9.93512 1.625 9.5 1.625C9.06488 1.625 8.64758 1.79785 8.3399 2.10553C8.03223 2.4132 7.85938 2.8305 7.85938 3.26562ZM6.54688 3.59375V3.26562C6.54688 2.48241 6.85801 1.73127 7.41183 1.17745C7.96564 0.623632 8.71678 0.3125 9.5 0.3125C10.2832 0.3125 11.0344 0.623632 11.5882 1.17745C12.142 1.73127 12.4531 2.48241 12.4531 3.26562V3.59375H17.375C17.549 3.59375 17.716 3.66289 17.839 3.78596C17.9621 3.90903 18.0312 4.07595 18.0312 4.25C18.0312 4.42405 17.9621 4.59097 17.839 4.71404C17.716 4.83711 17.549 4.90625 17.375 4.90625H16.3854L15.1438 15.779C15.0522 16.5797 14.6691 17.3188 14.0676 17.8552C13.4661 18.3916 12.6882 18.6879 11.8822 18.6875H7.11781C6.31185 18.6879 5.53393 18.3916 4.9324 17.8552C4.33087 17.3188 3.94781 16.5797 3.85625 15.779L2.61462 4.90625H1.625C1.45095 4.90625 1.28403 4.83711 1.16096 4.71404C1.03789 4.59097 0.96875 4.42405 0.96875 4.25C0.96875 4.07595 1.03789 3.90903 1.16096 3.78596C1.28403 3.66289 1.45095 3.59375 1.625 3.59375H6.54688ZM8.1875 7.85938C8.1875 7.68533 8.11836 7.51841 7.99529 7.39534C7.87222 7.27227 7.7053 7.20312 7.53125 7.20312C7.3572 7.20312 7.19028 7.27227 7.06721 7.39534C6.94414 7.51841 6.875 7.68533 6.875 7.85938V14.4219C6.875 14.5959 6.94414 14.7628 7.06721 14.8859C7.19028 15.009 7.3572 15.0781 7.53125 15.0781C7.7053 15.0781 7.87222 15.009 7.99529 14.8859C8.11836 14.7628 8.1875 14.5959 8.1875 14.4219V7.85938ZM11.4688 7.20312C11.2947 7.20312 11.1278 7.27227 11.0047 7.39534C10.8816 7.51841 10.8125 7.68533 10.8125 7.85938V14.4219C10.8125 14.5959 10.8816 14.7628 11.0047 14.8859C11.1278 15.009 11.2947 15.0781 11.4688 15.0781C11.6428 15.0781 11.8097 15.009 11.9328 14.8859C12.0559 14.7628 12.125 14.5959 12.125 14.4219V7.85938C12.125 7.68533 12.0559 7.51841 11.9328 7.39534C11.8097 7.27227 11.6428 7.20312 11.4688 7.20312Z"
                fill="#f1504e"
              />
            </svg>
          )}
        </span>
      )}
      <input
        id="file-upload"
        type="file"
        className="file-upload-input"
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </>
  );
};

export default ChatboardNew;
