import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Form,
  Modal,
  Nav,
  Pagination,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useCallback, useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Profile from "../../assets/img/team/profile.png";
import CommonCard from "../../components/CommonCard";
import Navbar from "../../components/Navbar";
import NoRecordFound from "../../components/NoRecordFound";
import Spinner from "../../components/spinner";
import { markAsFavouriteJob } from "../../Redux/addJob/actions";
import { getJobFilter } from "../../Redux/Dashboard/actions";
import { getCategoryList } from "../../Redux/Category/actions";
import ProfileCard from "../../components/profileCard";
const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";

const Search = () => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const SearchFilter = useSelector((state) => state?.Seeker?.FilterList);
  const Filter = useSelector((state) => state?.Seeker?.FilterList);
  const SelectedCategory = useSelector(
    (state) => state?.Category?.getCategoryList
  );
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [location, setLocation] = useState([]);
  const [distance, setDistance] = useState();
  const [hourlyRate, setHourlyRate] = useState();
  const [accountType, setAccountType] = useState("Jobs");
  const [search, setSearch] = useState("");
  const [longitude, setLogintude] = useState();
  const [latitude, setLatitude] = useState();
  const [rating, setRating] = useState(0); // initial rating value
  const [limit] = useState("10");
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (Filter !== undefined) {
      setData(Filter?.jobs);
    }
  }, [Filter]);
  const nextPage = () => {
    if (page < SearchFilter?.pages) {
      setPage(page + 1);
    }
  };
  const previousPage = () => {
    if (1 > page) {
      setPage(page - 1);
    }
  };

  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= SearchFilter?.pages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === page}
          onClick={() => {
            setPage(number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };
  useEffect(() => {
    if (SelectedCategory !== undefined) {
      let array = [
        {
          id: "",
          value: "ALL",
          label: "All",
        },
      ];
      array.push(
        ...SelectedCategory.map((item) => ({
          id: item?.id,
          value: item?.title,
          label: item?.title,
        }))
      );
      setCategoryList(array);
    }
  }, [SelectedCategory]);
  const Distance = [
    {
      value: "None",
      label: "None",
    },
    {
      value: "100",
      label: "100 KM",
    },
    {
      value: "200",
      label: "200 KM",
    },
    {
      value: "300",
      label: "300 KM",
    },
  ];
  const Rating = [
    {
      value: "None",
      label: "None",
    },
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
  ];
  const Hourly = [
    {
      value: "Low",
      label: "Low",
    },
    {
      value: "High",
      label: "High",
    },
  ];
  const handleClicks = (event) => {
    setRating(event.target.value);
  };
  const handleDistance = (event) => {
    setDistance(event.target.value);
  };
  const handleHourly = (event) => {
    setHourlyRate(event.target.value);
  };
  useEffect(() => {
    dispatch(
      getCategoryList({
        role: "user",
        page: page,
        limit: limit,
        search: "",
      })
    );
  }, [dispatch, limit, page]);
  const { ref } = usePlacesWidget({
    apiKey: YOUR_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      setLocation(place.formatted_address);
      setLogintude(place.geometry.location.lng());
      setLatitude(place.geometry.location.lat());
    },
    options: {
      componentRestrictions: {},
      types: ["address"],
      fields: [
        "address_components",
        "formatted_address",
        "geometry",
        "name",
        "place_id",
      ],
    },
    defaultValue: location,
  });
  useEffect(() => {
    dispatch(
      getJobFilter({
        page: page ? page : "",
        limit: limit ? limit : "",
        category: categories ? categories : "",
        rating: rating ? rating : "",
        hourlyRate: hourlyRate ? hourlyRate : "",
        longitude: longitude ? longitude : "",
        latitude: latitude ? latitude : "",
        distance: distance ? distance : "",
        location: location,
        search: search,
        setLoader: setLoader,
      })
    );
  }, [
    page,
    limit,
    categories,
    rating,
    hourlyRate,
    longitude,
    latitude,
    distance,
    search,
    dispatch,
    location,
  ]);
  const handleClick = useCallback(
    (id, value, isFavourite) => {
      const newArray = [...data];
      const item = { ...newArray[value] }; // Copy the item to modify
      item.isFavourite = !isFavourite; // Toggle the 'isFavourite' property
      newArray[value] = item; // Replace the old item with the new item
      setData(newArray);
      dispatch(
        markAsFavouriteJob({
          id: id,
        })
      );
    },
    [data, dispatch]
  );
  const handleAccountType = (event) => {
    setAccountType(event.target.value);
  };

  const renderCards = useCallback(
    (items) => {
      return items?.map((value, index) => (
        <Col
          lg={2}
          md={4}
          sm={6}
          xs={12}
          className="pb-3"
          key={value.id || index}
        >
          {accountType === "User" ? (
            <ProfileCard
              img={value?.user?.profileImg || Profile}
              name={value.user?.fullName}
              type={"IT"}
              id={value.user?.id}
              star={value.user?.profile_rating}
              completed={value.user?.completeJobs || "0"}
            />
          ) : (
            <CommonCard
              img={value?.images ? value?.images : Profile}
              name={value?.name ? value?.name : "N/A"}
              jobType={value?.jobType ? value?.jobType : "N/A"}
              id={value?.id}
              index={index}
              handleClick={handleClick}
              rate={value?.rate ? value?.rate : "N/A"}
              completed={"90"}
              star={value?.rating ? value?.rating * 20 : "0"}
              isFavourite={value?.isFavourite}
            />
          )}
        </Col>
      ));
    },
    [accountType, handleClick]
  );

  return (
    <>
      <Navbar module={"Job Filters"} />
      <Row className="pt-2 pb-4">
        <Col lg={9} md={9} className="mb-2 pl-3">
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Search"
              label="Search"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col lg={3} md={3} className="mb-2 pl-3">
          <Form.Group>
            <Form.Select
              label="Type"
              value={accountType}
              onChange={handleAccountType}
            >
              <option value="Jobs">Jobs</option>
              <option value="User">User</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col lg={12} md={12} xs={12} className="pb-3 mb-3">
          <Modal.Header>
            <Col lg={2} md={3} className="mb-1 pl-3">
              Location
              <Form.Control ref={ref} style={{ width: "100%" }} />
            </Col>

            <Col lg={2} md={3} className="mb-1">
              <Form.Group>
                Distance
                <Form.Select
                  // defaultValue="1"
                  label="Select"
                  value={distance}
                  onChange={handleDistance}
                >
                  {Distance.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={2} md={3} className="mb-1">
              <Form.Group>
                Category
                <Select
                  defaultValue={categories}
                  onChange={(e) => setCategories(e.id)}
                  options={categoryList}
                />
              </Form.Group>
            </Col>

            <Col lg={2} md={3} className="mb-1">
              <Form.Group>
                Rating
                <Form.Select
                  defaultValue="1"
                  label="Select"
                  value={rating}
                  onChange={handleClicks}
                >
                  {Rating.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={2} md={3} className="mb-1">
              <Form.Group>
                Hourly Rate
                <Form.Select
                  defaultValue="1"
                  placeholder="Hourly Rate"
                  label="Select"
                  value={hourlyRate}
                  onChange={handleHourly}
                >
                  {Hourly.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Modal.Header>
        </Col>
        {loader ? (
          <Spinner />
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                {renderCards(data)}
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination size={"sm"} className="mb-2 mb-lg-0">
                      <Pagination.Prev onClick={() => previousPage()}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Pagination.Prev>
                      {paginationItems().length > 10 ? (
                        <>
                          {paginationItems().slice(0, 10)}
                          <Pagination.Ellipsis />
                          {paginationItems().slice(-10)}
                        </>
                      ) : (
                        paginationItems()
                      )}
                      <Pagination.Next onClick={() => nextPage()}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Pagination.Next>
                    </Pagination>
                  </Nav>
                  <small className="fw-bold">
                    Showing <b>{SearchFilter?.jobs?.length}</b> out of{" "}
                    <b>{SearchFilter?.total_jobs}</b> entries
                  </small>
                </Card.Footer>
              </>
            ) : (
              <NoRecordFound />
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default Search;
