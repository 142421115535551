import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { getProfile, updateAdminProfile } from "../../Redux/profile/actions";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Autocomplete from "react-google-autocomplete";
import Select from "react-select";
import ReactPlayer from "react-player";

const languageOptions = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
];

const autocompleteOptions = {
  types: ['address'],
  fields: ['formatted_address', 'geometry', 'address_components']
};

const EditProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBJWt1Yh6AufjxV8B8Y8UVz_25cYV1fvhs";
  const login = useSelector((state) => state.auth.Auther);
  const getById = useSelector((state) => state.ProfileReducer.profile);
  const [selectedImage, setSelectedImage] = useState();
  const [location, setLocation] = useState(getById?.address || "");
  const [longitude, setLogintude] = useState(getById?.longitude);
  const [latitude, setLatitude] = useState(getById?.latitude);
  const [setLoader] = useState(true);
  const [isBusinessProfile] = useState(false);
  const [businessLicense] = useState(null);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [videoURL, setVideoURL] = useState("");
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    ...(login?.role?.name === "customer" && {
      dateOfBirth: Yup.string().required("Date Of Birth is required"),
      phoneNumber: Yup.string().test(
        "isValidPhoneNumber",
        "Invalid phone number",
        (value) => value && isValidPhoneNumber(value)
      ),
      postalCode: Yup.string().required("Postal Code is required"),
      city: Yup.string().required("City is required"),
      address: Yup.string().required("Address is required"),
      // industryType: Yup.string().trim().required("industryType is Required"),
      // videoURL: Yup.string()
      //   .url("Invalid URL")
      //   .required("Video URL is required"),
      // TODO: Do not delete this code
      // Commenting out the business profile-related validation
      // businessLicense: Yup.mixed().required('Business License is required')
    }),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, control, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;
  const [dateofBirth] = React.useState(new Date());
  const [user, setUser] = useState();

  useEffect(() => {
    reset(user);
  }, [user, reset]);

  useEffect(() => {
    setUser({
      fullName: getById?.fullName,
      address: getById?.address,
      dateofBirth: getById?.dateOfBirth
        ? moment(getById.dateOfBirth).format("YYYY-MM-DD")
        : "",
      phoneNumber: getById?.phoneNumber,
      city: getById?.city,
      postalCode: getById?.postalCode,
      profileImg: getById?.profileImg,
      ...(login?.role?.name === "customer" && {
        videoURL: getById?.videoUrl,
        businessLicense: getById?.businessLicense,
      }),
    });
    setVideoURL(getById?.videoUrl);
    setSelectedLanguages(getById?.language?.map((language) => language.name));
    setLogintude(getById?.longitude);
    setLatitude(getById?.latitude);
  }, [getById, login?.role?.name]);
  useEffect(() => {
    dispatch(
      getProfile({
        profileId: login?.id,
        setLoader: setLoader,
      })
    );
  }, [dispatch, login?.id, setLoader]);
  function onSubmit(data) {
    if (
      login?.role?.name === "customer" &&
      !isValidPhoneNumber(data.phoneNumber)
    ) {
      return;
    } else {
      const profileData = {
        fullName: data.fullName,
        id: getById.id,
        profileImg: selectedImage ? selectedImage : getById?.profileImg,
        history: navigate,
        setLoader: setLoader,
        ...(login?.role?.name === "customer" && {
          address: location,
          latitude: latitude,
          longitude: longitude,
          dateofBirth: dateofBirth
            ? moment.utc(dateofBirth).format().toString()
            : getById?.dateofBirth.toString(),
          phoneNumber: data.phoneNumber
            ? data.phoneNumber
            : getById?.phoneNumber,
          city: data.city,
          postalCode: data.postalCode,
          isBusinessProfile: isBusinessProfile,
          businessLicense: businessLicense,
          languages: selectedLanguages,
          videoURL: data.videoURL,
        }),
      };

      dispatch(updateAdminProfile(profileData));
    }
  }
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const videoUpload = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setVideoURL(e.target.files[0]);
    }
  };

  const inputEl = useRef(null);
  const onButtonClick = () => {
    inputEl.current.click();
  };

  useEffect(() => {
    if (getById?.phoneNumber !== undefined) {
      setValue("phoneNumber", getById?.phoneNumber);
    }
    if (getById?.address !== undefined) {
      setLocation(getById?.address);
    }
  }, [getById?.phoneNumber, getById?.address, setValue]);

  return (
    <>
      {user && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-center p-0 mb-4 profileView"
                    style={{ cursor: "pointer", height: "300px" }}
                  >
                    {selectedImage ? (
                      <Card.Img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Neil Portrait"
                        onClick={onButtonClick}
                        ref={inputEl}
                        className="user-avatar large-avatar rounded-circle mx-auto mt-5"
                      />
                    ) : (
                      <Card.Img
                        src={getById?.profileImg}
                        alt="Neil Portrait"
                        onClick={onButtonClick}
                        ref={inputEl}
                        className="user-avatar large-avatar rounded-circle mx-auto mt-5"
                      />
                    )}
                    <Form.Group className="col my-2">
                      <Form.Control
                        accept="image/*"
                        type="file"
                        id="file"
                        name="file"
                        onChange={imageChange}
                        className="d-none"
                        ref={inputEl}
                        style={{ cursor: "pointer" }}
                      />
                      <div className="invalid-feedback">
                        {errors.file?.message}
                      </div>
                    </Form.Group>
                    <Card.Body className="pb-5">
                      <Dropdown.Menu className="custom_menu">
                        <Dropdown.Item onClick>
                          <FontAwesomeIcon icon={faEdit} className="me-2" />{" "}
                          Edit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                      <Card.Subtitle
                        className="text-gray mb-2"
                        onClick={onButtonClick}
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Profile image
                      </Card.Subtitle>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {login?.role?.name === "customer" && (
                <Row>
                  <Col xs={12}>
                    <Card
                      border="light"
                      className="text-center p-0 mb-4 profileView"
                      style={{ cursor: "pointer", height: "300px" }}
                    >
                      <Form.Group className="col my-2">
                        <Form.Label>Video File</Form.Label>
                        <Form.Control
                          name="videoUrl"
                          type="file"
                          onChange={videoUpload}
                        />
                      </Form.Group>
                      {videoURL && (
                        <ReactPlayer
                          url={videoURL}
                          width="100%"
                          height="100%"
                          controls={true}
                        />
                      )}
                    </Card>
                  </Col>
                </Row>
              )}
            </Col>
            <Col xs={12} xl={8}>
              <Card
                border="light"
                className="text-left p-0 mb-4 profileView info"
              >
                <Card.Body className="pb-3">
                  <Card.Title className="text-primary">
                    Basic Information
                  </Card.Title>

                  <Form.Group className="col my-2">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="fullName"
                      type="text"
                      {...register("fullName")}
                      className={`form-control ${
                        errors.fullName ? "is-invalid" : ""
                      }`}
                    />
                    <div className="invalid-feedback">
                      {errors.fullName?.message}
                    </div>
                  </Form.Group>
                  {login?.role?.name === "customer" && (
                    <>
                      <Form.Group className="col my-2">
                        <Form.Label>Date Of Birth</Form.Label>
                        <Form.Control
                          name="dateOfBirth"
                          type="date"
                          {...register("dateOfBirth")}
                          defaultValue={
                            getById?.dateOfBirth
                              ? new Date(getById.dateOfBirth)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          } // Set default value for date in YYYY-MM-DD format
                          className={`form-control ${
                            errors.dateOfBirth ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.dateOfBirth?.message}
                        </div>
                      </Form.Group>
                      <Form.Group className="col my-2">
                        <Form.Label>Phone</Form.Label>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput
                              {...field}
                              error={
                                field.value
                                  ? isValidPhoneNumber(field.value)
                                    ? undefined
                                    : "Invalid phone number"
                                  : "Phone number required"
                              }
                            />
                          )}
                        />
                        <div className="invalid-phone">
                          {errors.phoneNumber && errors.phoneNumber.message}
                        </div>
                      </Form.Group>
                      <Form.Group className="col my-2">
                        <Form.Label>Address</Form.Label>
                        <Controller
                          name="address"
                          control={control}
                          render={({ field }) => (
                            <Autocomplete
                              apiKey={YOUR_GOOGLE_MAPS_API_KEY}
                              style={{ width: "100%" }}
                              className={`form-control pac-target-input ${
                                errors.address ? "is-invalid" : ""
                              }`}
                              options={autocompleteOptions}
                              onPlaceSelected={(place) => {
                                // Extract address components
                                const addressComponents = place.address_components || [];
                                
                                // Find postal code and city
                                const postalCode = addressComponents.find(
                                  component => component.types.includes('postal_code')
                                )?.long_name;
                                
                                const city = addressComponents.find(
                                  component => component.types.includes('locality')
                                )?.long_name;
                                
                                // Auto-fill postal code and city if available
                                if (postalCode) {
                                  setValue('postalCode', postalCode);
                                }
                                if (city) {
                                  setValue('city', city);
                                }
                                
                                setLocation(place.formatted_address);
                                setLogintude(place.geometry.location.lng());
                                setLatitude(place.geometry.location.lat());
                                field.onChange(place.formatted_address);
                              }}
                              value={location}
                              onChange={(e) => {
                                setLocation(e.target.value);
                                field.onChange(e.target.value);
                              }}
                              defaultValue={getById?.address}
                            />
                          )}
                        />
                        <div className="invalid-feedback">
                          {errors.address?.message}
                        </div>
                      </Form.Group>
                      <Form.Group className="col my-2">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control
                          name="PostalCode"
                          type="text"
                          {...register("postalCode")}
                          className={`form-control ${
                            errors.postalCode ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.postalCode?.message}
                        </div>
                      </Form.Group>
                      <Form.Group className="col my-2">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          name="City"
                          type="text"
                          {...register("city")}
                          className={`form-control ${
                            errors.city ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.city?.message}
                        </div>
                      </Form.Group>
                    </>
                  )}

                  {login?.role?.name === "customer" && (
                    <>
                      <Form.Group className="col my-2">
                        <Form.Label>Languages</Form.Label>
                        <Controller
                          name="languages"
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              isMulti
                              options={languageOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(selectedOptions) => {
                                setSelectedLanguages(
                                  selectedOptions.map((option) => option.value)
                                ); // Save selected values in state
                              }}
                              value={selectedLanguages?.map((value) =>
                                languageOptions.find(
                                  (option) => option.value === value
                                )
                              )} // Set selected values
                            />
                          )}
                        />
                      </Form.Group>

                      {/* TODO: Do not delete this code */}
                      {/* Commenting out the business profile section */}
                      {/* <Form.Group className="col my-2">
                        <Form.Check
                          type="checkbox"
                          label="Is this a business profile?"
                          onChange={e => setIsBusinessProfile(e.target.checked)}
                        />
                      </Form.Group>
                      {isBusinessProfile && (
                        <Form.Group className="col my-2">
                          <Form.Label>Business License</Form.Label>
                          <Form.Control
                            name="businessLicense"
                            type="file"
                            required
                            accept="application/pdf"
                            onChange={fileChange}
                          />
                        </Form.Group>
                      )} */}
                    </>
                  )}
                  <Form.Group>
                    <div className="d-grid gap-2 col-4 text-center mt-3 mx-auto">
                      <Button
                        variant="primary"
                        color="dark"
                        size="sm"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export default EditProfile;
