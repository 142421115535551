import React, { useEffect, useCallback, useState } from "react";
import {
  Col,
  Table,
  Row,
  Form,
  Modal,
  Button,
  Card,
} from "@themesberg/react-bootstrap";
import Navbar from "../../components/Navbar";
import cardSvg from "./card.svg";
import { toast } from "react-toastify";
import {
  CardExpiryElement,
  CardCvcElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import {
  addCardDetails,
  getCardDetails,
  deleteCard,
  selectCard,
} from "../../Redux/settings/actions";
import Visa from "../../assets/img/visa.png";
import MasterCard from "../../assets/img/mastercard.jpeg";
import American from "../../assets/img/american-express.png";
import Club from "../../assets/img/diners-club.png";

const AddCard = () => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const login = useSelector((state) => state.auth.Auther);
  const cardDetail = useSelector(
    (state) => state?.PushNotification?.cardDetails
  );
  const [loader, setLoader] = useState();
  const handleCloses = () => setShowModal(false);
  useEffect(() => {
    dispatch(getCardDetails(login?.id));
  }, [login?.id, dispatch]);
  const handleAddCard = useCallback(
    async (stripe, elements) => {
      setLoader(true);
      const cardElement = elements.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      );
      await stripe.createToken(cardElement).then(async (res) => {
        if (res.error) {
          toast.error(res.error.message);
        } else {
          let data = {
            token: res.token.id,
            userId: login?.id,
            setShowModal: setShowModal,
            setLoader: setLoader,
          };
          dispatch(addCardDetails(data));
        }
      });
    },
    [login?.id, dispatch]
  );
  const dispatchWithPromise = (action) =>
    new Promise((resolve) => {
      dispatch(action);
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  const handleCheckboxChange = async (index, card) => {
    await dispatchWithPromise(selectCard({ card, setLoader: setLoader }));
    await dispatchWithPromise(getCardDetails(login?.id));
  };
  const handleDeleteCard = async (index, card) => {
    await dispatchWithPromise(deleteCard({ card, setLoader: setLoader }));
    await dispatchWithPromise(getCardDetails(login?.id));
  };
  return (
    <>
      <Navbar module={"Payement Method"} />
      <Row>
        <Col xs={12}>
          <Card border="light" className="p-0 mb-4 profileView">
            <Card.Header>
              <span>Credit/Debit Card Details</span>
            </Card.Header>
            <Card.Body>
              <div className="text-center">
                <img src={cardSvg} alt="" />
              </div>

              <Table
                hover
                className="user-table align-items-center management_table mt-5"
              >
                <thead>
                  <tr>
                    <th className="border-bottom">Card Name</th>
                    <th className="border-bottom">Card Number</th>
                    <th className="border-bottom">Card Expiry</th>
                    <th className="border-bottom">Select</th>
                    <th className="border-bottom">Delete Card</th>
                  </tr>
                </thead>
                {cardDetail && (
                  <tbody>
                    {cardDetail?.map((card, index) => (
                      <tr key={index}>
                        <td>
                          <span className="fw-normal ">
                            {card?.brand === "Visa" && (
                              <img
                                src={Visa}
                                alt=""
                                width="25px"
                                className="mx-2"
                              />
                            )}
                            {card?.brand === "American Express" && (
                              <img
                                src={American}
                                alt=""
                                width="25px"
                                className="mx-2"
                              />
                            )}
                            {card?.brand === "Diners Club" && (
                              <img
                                src={Club}
                                alt=""
                                width="25px"
                                className="mx-2"
                              />
                            )}
                            {card?.brand === "MasterCard" && (
                              <img
                                src={MasterCard}
                                alt=""
                                width="25px"
                                className="mx-2"
                              />
                            )}
                            {card?.brand}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {`****-****-${
                              card?.last4 !== undefined
                                ? card?.last4
                                : "********"
                            }`}
                          </span>
                        </td>
                        <td>
                          <span className="fw-normal">
                            {`${
                              card?.exp_month !== undefined
                                ? card?.exp_month
                                : "****"
                            }-${
                              card?.exp_year !== undefined
                                ? card?.exp_year
                                : "****"
                            }`}
                          </span>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            disabled={loader}
                            checked={card.isActive}
                            onChange={() => handleCheckboxChange(index, card)}
                          />
                        </td>
                        <td>
                          <Button
                            variant="primary"
                            className="mx-2"
                            disabled={loader}
                            onClick={() => handleDeleteCard(index, card)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
            </Card.Body>
            <Card.Footer className="text-center">
              <Button
                variant="primary"
                className="mx-2"
                onClick={() => setShowModal(true)}
              >
                {cardDetail[0]?.id ? "Add Another Card" : "Add Card"}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      {/* Modal */}
      <Modal as={Modal.Dialog} centered show={showModal} onHide={handleCloses}>
        <Modal.Header>
          <span>Add Card Details</span>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img src={cardSvg} alt="" />
            <div className="carfForm text-start">
              <Form.Label>Card Number</Form.Label>
              <CardNumberElement className="cardNumber my-1" />
              <Form.Label>Expiry Date</Form.Label>
              <div className="d-flex justify-content-between">
                <CardExpiryElement className="cardexpiray" />
                <CardCvcElement className="cardexpiray" />
              </div>
            </div>
            <Button
              variant="primary"
              className="mx-2"
              disabled={loader}
              onClick={() => handleAddCard(stripe, elements)}
            >
              Add Card
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddCard;
