import { toast } from "react-toastify";
import { all, fork, put, select, takeLatest, call } from "redux-saga/effects";
import axios from "../../Routes/axiosConfig";
import { sagaErrorHandler } from "../../Shared/shared";
import { makeSelectAuthToken, makeSelectAuthRole } from "../../Store/selector";
import {
  // getJobs,
  // getAddJob,
  // getGigs,
  getRejectedApplicantsSuccess,
  getJobListingSuccess,
  getJobsSuccess,
  getGigsSuccess,
  favouriteJobListSuccess,
  // deleteAddJob,
  jobByIdSuccess,
  updateJobSuccess,
  // getJobListing,
  getApplicants,
  getApplicantsSuccess,
  // getConfirmSuccess,
  // getLogHours,
  getLogHoursSuccess,
  getApprovedHoursSuccess,
  // getConfirmApplicants,
  // getSingleUser,
  getSingleUserSuccess,
  getHiredApplicantsSuccess,
  getHiredApplicants,
  getApplicantsByUserId,
  getShortListApplicantsSuccess,
  // extendJobTime,
  extendJobTimeSuccess,
  getExtendSuccess,
  getScheduleDaysSuccess,
} from "./actions";
import {
  ADD_JOB,
  ADD_WORKER_JOB,
  GET_JOB,
  APPLY_JOB,
  GET_DAYS,
  GET_GIGS,
  FAVOURITE_JOB_LIST,
  DELETE_ADD_JOB,
  MARK_AS_FAVOURITE_JOB,
  GET_SHORTLIST_APPLICANTS,
  JOB_BY_ID,
  SHORTLIST_APPLICANTS,
  UPDATE_JOB,
  GET_JOB_APPLICANTS,
  GET_HIRED_APPLICANTS,
  POST_LOG_HOURS,
  CONFIRM_HIRING,
  CONFIRM_APPLICANTS,
  GET_LOG_HOURS,
  START_JOB,
  APPROVED_LOG_HOURS,
  CONFIRM_JOB_BY_WORKER,
  REJECTED_APPLICANTS,
  GET_SINGLE_USER,
  RATE_PROVIDER,
  COMPLETE_JOB,
  CONFIRM_JOB,
  GET_APPLICANTS_BYUSERID,
  EMERGENCY_JOB,
  SEND_OFFER,
  EXTEND_TIME,
  GET_EXTEND,
} from "./constants";
import { CapitalizeFirstLetter } from "../../utils/Global";
import { SendMessage } from "../chat/actions";

function* addJob({ payload }) {
  let repost = payload.isPost;
  const formData = new FormData();
  formData.append("name", payload.name);
  formData.append("description", payload.description);
  formData.append("requirement", payload.requirement);
  formData.append("paymentType", payload.paymentType);
  formData.append("rate", payload.rate);
  formData.append("unit", payload.unit);
  formData.append("questions", JSON.stringify(payload.questions));
  // formData.append("days", payload.days);
  formData.append("location", JSON.stringify([payload.location]));
  formData.append("longitude", payload.longitude);
  formData.append("latitude", payload.latitude);
  formData.append("noOfProviders", payload.noOfProviders);
  formData.append("toolsNeeded", payload.toolsNeeded);
  formData.append("experienceRequired", payload.experienceRequired);
  // formData.append("jobType", payload.jobType);
  // formData.append("jobType", payload.paymentType === "fixed" ? null : payload.jobType);
  // formData.append("jobPlace", payload.jobPlace);
  formData.append("jobNature", payload.jobNature);
  // formData.append("jobNature", payload.jobNature);
  formData.append("startDate", payload?.startDate);
  formData.append(
    "endDate",
    payload.jobNature === "Recurring" ? null : payload?.endDate
  );
  formData.append("jobType", payload.jobType);
  // formData.append("isOngoing", payload.isOngoing);
  formData.append("category", payload.category);
  payload.jobImg.forEach((file) => {
    !file.name && formData.append("jobImgs", file.file);
  });
  if (payload.image) formData.append("jobImgs", payload.image);
  formData.append("jobDays", JSON.stringify(payload.logDays));
  formData.append("startTime", payload.startTime);
  formData.append("endTime", payload.endTime);
  if (repost) formData.append("existImg", payload.existImg);
  // formData.append("isPost", payload.isPost);
  try {
    payload.setButtonDisabled(true);
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(
      `${repost === true ? "job/repost" : "job/customer"}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    payload.setButtonDisabled(false);
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.setReset();
    payload.history("/job");
  } catch (error) {
    payload.setButtonDisabled(false);
    yield sagaErrorHandler(error.response);
  }
}
function* watchAddJob() {
  yield takeLatest(ADD_JOB, addJob);
}

function* applyjobByWorker({ payload }) {
  let data = {
    job: payload.jobId,
    rate: payload.rate,
    description: payload.proposalDescription,
    answers: payload.answers,
  };
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/worker/apply`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    yield call(jobByIdSaga, {
      payload: { id: payload.jobId, setLoader: payload.setLoader },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    // payload.setReset();
    payload.setApply(false);
  } catch (error) {
    payload.setApply(false);
    yield sagaErrorHandler(error.response);
  }
}
function* watchApplyJob() {
  yield takeLatest(APPLY_JOB, applyjobByWorker);
}

function* confirmHiring({ payload }) {
  let { isOffer } = payload;
  let data = {
    jobId: payload.jobId,
    isConfirmed: true,
    userId: payload.seekerId,
  };
  let offerPayload = {
    jobId: payload.jobId,
    isConfirmed: true,
    customerId: payload.customerId,
  };
  try {
    const token = yield select(makeSelectAuthToken());
    let url = isOffer ? "job/provider/confirm/hiring" : "job/worker/confirm";
    let newData = isOffer ? offerPayload : data;
    const response = yield axios.post(url, newData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    toast.success(CapitalizeFirstLetter(response.data.message));
    yield call(jobByIdSaga, {
      payload: { id: payload.jobId, setLoader: false },
    });
    // payload.setReset();
    // payload.setApply(false);
  } catch (error) {
    payload.setApply(false);
    yield sagaErrorHandler(error.response);
  }
}
function* watchConfirmHiring() {
  yield takeLatest(CONFIRM_HIRING, confirmHiring);
}

function* addWorkerJob({ payload }) {
  const { currentUser, zoom, users } = payload;

  let url = payload.isOffer ? "job/offer" : "job/worker";
  const formData = new FormData();
  if (!payload.isOffer) formData.append("name", payload.name);
  formData.append("description", payload.description);
  if (!payload.isOffer) formData.append("requirement", payload.requirement);
  if (!payload.isOffer) formData.append("paymentType", payload.paymentType);
  formData.append("rate", payload.rate);
  formData.append("location", JSON.stringify([payload.location]));
  formData.append("longitude", payload.longitude);
  formData.append("latitude", payload.latitude);
  if (!payload.isOffer) formData.append("jobType", payload.jobType);
  if (!payload.isOffer) formData.append("category", payload.category);
  if (payload.isOffer) formData.append("serviceId", payload.category);
  if (payload.startDate) formData.append("startDate", payload.startDate);
  if (payload.endDate) formData.append("endDate", payload.endDate);
  if (payload.customerId) formData.append("customerId", payload.customerId);
  if (payload.isOffer) formData.append("isOffer", payload.isOffer);
  if (!payload.isOffer) {
    payload.jobImg.forEach((file) => {
      !file.name && formData.append("jobImgs", file.file);
    });
  }
  if (!payload.isOffer) {
    if (payload.image) formData.append("jobImgs", payload.image);
    formData.append("startTime", payload.startTime);
    formData.append("endTime", payload.endTime);
  }
  try {
    payload.setButtonDisabled(true);
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(url, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    if (payload?.isOffer) {
      let customKey = true;
      let message = "custom-offer";
      let data = {
        senderId: currentUser.id,
        receiverId: payload.customerId,
        message: message,
      };
      const jobOffer = {
        offeredPrice: payload.rate,
        title: payload.name ?? "Customer Offer",
        jobId: response.data.data.id,
        offerTo: payload?.customerId,
        offerStatus: "Pending", //Pending,Rejected,Accepted
      };
      yield put(
        SendMessage({
          jobOffer,
          data,
          message,
          customKey,
          currentUser,
          zoom,
          users,
        })
      );
      payload.setCustomOffer(false);
    } else {
      payload.setReset();
      payload.setButtonDisabled(false);
      payload.history("/gigs");
    }
  } catch (error) {
    if (payload.isOffer) {
      payload.setCustomOffer(false);
    }
    payload.setButtonDisabled(false);
    yield sagaErrorHandler(error.response);
  }
}
function* watchAddWorkerJob() {
  yield takeLatest(ADD_WORKER_JOB, addWorkerJob);
}

function* getJobList({ payload }) {
  let category = payload.category === undefined ? "" : payload.category;
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/customer/${payload.userId}?page=${payload.page}&count=${payload.limit}&status=${payload.type}&category=${category}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getJobsSuccess(response.data.data));
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetJob() {
  yield takeLatest(GET_JOB, getJobList);
}

function* getGigsList({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/gigs?page=${payload.page}&count=${payload.limit}&status=${payload.type}&search=${payload.search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getGigsSuccess(response.data.data));
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetGigs() {
  yield takeLatest(GET_GIGS, getGigsList);
}
function* getFavoutiteJobList({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/favorite?page=${payload.page}&count=10`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // toast.success(CapitalizeFirstLetter(response.data.message));
    yield put(favouriteJobListSuccess(response.data.data));
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetFavouriteJob() {
  yield takeLatest(FAVOURITE_JOB_LIST, getFavoutiteJobList);
}
function* deleteJobSaga({ payload }) {
  // let { adminId } = payload;
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.delete(`job/customer/${payload.jobId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      // toast.success(CapitalizeFirstLetter(response.data.message));
      payload.setShowDefault(false);
      payload.history("/job");
    }
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchDeleteAddJob() {
  yield takeLatest(DELETE_ADD_JOB, deleteJobSaga);
}
function* markAsFavouriteJobSaga({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const datas = {
      helo: "heloo",
    };
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    yield axios.patch(`job/favorite/${payload.id}`, datas, headers);
    // let res = {
    //   page:1,
    //   setLoader:payload.setLoader
    // }
    // yield put(favouriteJobList(res));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchMarkAsFavouriteJob() {
  yield takeLatest(MARK_AS_FAVOURITE_JOB, markAsFavouriteJobSaga);
}
function* getScheduleDaysSaga() {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`schedule/getDays`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getScheduleDaysSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetScheduleDays() {
  yield takeLatest(GET_DAYS, getScheduleDaysSaga);
}
function* jobByIdSaga(payload) {
  const { id, setLoader } = payload.payload;
  if (setLoader) setLoader(true);
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`job/getJob/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (setLoader) setLoader(false);
    yield put(jobByIdSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchJobById() {
  yield takeLatest(JOB_BY_ID, jobByIdSaga);
}
function* updateJobSaga({ payload }) {
  const userRole = yield select(makeSelectAuthRole());
  const formData = new FormData();
  // formData.append("id", payload.id);
  formData.append("name", payload?.name);
  formData.append("description", payload?.description);
  formData.append("requirement", payload?.requirement);
  formData.append("paymentType", payload?.paymentType);
  formData.append("rate", payload?.rate);
  formData.append("unit", payload?.unit);
  // formData.append("hours", payload?.hours);
  // formData.append("days", payload?.days);
  formData.append("location", JSON.stringify([payload?.location]));
  formData.append("longitude", payload?.longitude);
  formData.append("latitude", payload?.latitude);
  formData.append("noOfProviders", payload?.noOfProviders);
  formData.append("toolsNeeded", payload?.toolsNeeded);
  formData.append("experienceRequired", payload?.experienceRequired);
  // formData.append("jobType", payload?.jobType);
  // formData.append("jobType", payload?.paymentType === "fixed" ? null : payload?.jobType);
  // formData.append("jobPlace", payload?.jobPlace);
  formData.append("jobNature", payload?.jobNature);
  // formData.append("jobNature", payload?.jobNature);
  formData.append("startDate", payload?.startDate);
  formData.append(
    "endDate",
    payload?.jobNature === "recurring" ? null : payload?.endDate
  );
  formData.append("jobType", payload?.jobType);
  formData.append("questions", JSON.stringify(payload.questions));
  // formData.append("isOngoing", payload?.isOngoing);
  formData.append("category", payload?.category);
  payload.jobImg.forEach((file) => {
    !file.name && formData.append("jobImgs", file.file);
  });
  if (payload.image) formData.append("jobImgs", payload.image);
  formData.append("jobDays", JSON.stringify(payload?.logDays));
  formData.append("startTime", payload?.startTime);
  formData.append("endTime", payload?.endTime);
  const formData2 = new FormData();
  formData2.append("name", payload?.name);
  formData2.append("description", payload?.description);
  formData2.append("paymentType", payload?.paymentType);
  formData2.append("rate", payload?.rate);
  formData2.append("location", JSON.stringify([payload?.location]));
  formData2.append("longitude", payload?.longitude);
  formData2.append("latitude", payload?.latitude);
  // formData2.append("jobType", payload?.jobType);
  // formData2.append("jobType", payload?.paymentType === "fixed" ? null : payload?.jobType);
  payload?.jobPlace && formData2.append("jobPlace", payload?.jobPlace);
  // formData2.append("jobNature", payload?.jobNature);
  formData2.append("jobType", payload?.jobType);
  formData2.append("category", payload?.category);
  payload.jobImg.forEach((file) => {
    !file.name && formData2.append("jobImgs", file.file);
  });
  if (payload.image) formData2.append("jobImgs", payload.image);
  formData2.append("startTime", payload?.startTime);
  formData2.append("endTime", payload?.endTime);

  try {
    payload.setButtonDisabled(true);
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(
      userRole === "customer"
        ? `job/customer/${payload.id}`
        : `job/worker/${payload.id}`,
      userRole === "customer" ? formData : formData2,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    payload.setButtonDisabled(false);
    yield put(updateJobSuccess(response.data));
    payload.setReset();
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.history(`/detailJob/${payload.id}`);

    // yield put(getJobListingSuccess(response.data.data));
  } catch (error) {
    payload.setButtonDisabled(false);
    yield sagaErrorHandler(error.response);
  }
}
function* watchUpdateJob() {
  yield takeLatest(UPDATE_JOB, updateJobSaga);
}
function* getApplicantsRequest(payload) {
  try {
    const { id, setLoader } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/applicants/${id}?page=${payload.payload.page}&count=${payload.payload.limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getApplicantsSuccess(response.data));
    setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetApplicants() {
  yield takeLatest(GET_JOB_APPLICANTS, getApplicantsRequest);
}
function* gethiredApplicantsSaga(payload) {
  try {
    const { id, setLoader } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/hiredApplicants/${id}?page=${payload.payload.page}&count=${payload.payload.limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getHiredApplicantsSuccess(response.data));
    setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetHiredApplicants() {
  yield takeLatest(GET_HIRED_APPLICANTS, gethiredApplicantsSaga);
}

function* getRejectedApplicants(payload) {
  try {
    const { id, setLoader } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/getRejectedCandidates/${id}?page=${payload.payload.page}&count=${payload.payload.limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getRejectedApplicantsSuccess(response.data));
    setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchRejectedApplicants() {
  yield takeLatest(REJECTED_APPLICANTS, getRejectedApplicants);
}

function* shortListApplicants(payload) {
  try {
    const { id, setLoader, userId, setIsDisabled } = payload.payload;
    setIsDisabled(true);
    let data = {
      jobId: id,
      user: userId,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/shortListWorker`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    setIsDisabled(false);
    toast.success(CapitalizeFirstLetter(response.data.message));
    yield put(
      getApplicants({ id: id, setLoader: setLoader, page: 1, limit: 50 })
    );
    setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchAddShortList() {
  yield takeLatest(SHORTLIST_APPLICANTS, shortListApplicants);
}

function* getShortListApplicants(payload) {
  try {
    const { id, setLoader } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/getOnJobStatus/${id}?page=${payload.payload.page}&count=${payload.payload.limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    yield put(getShortListApplicantsSuccess(response.data));
    setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetShortList() {
  yield takeLatest(GET_SHORTLIST_APPLICANTS, getShortListApplicants);
}

function* ConfirmSaga(payload) {
  try {
    payload.payload.setIsDisabled && payload.payload.setIsDisabled(true);
    let data = {
      isAccepted: payload.payload.isAccepted,
    };
    const { userId } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/approve/${userId}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.payload.setIsDisabled && payload.payload.setIsDisabled(false);
    // yield put(getConfirmSuccess(response.data));
    if (payload.payload.isShortList) {
      yield put(
        getShortListApplicants({
          id: payload.payload.id,
          setLoader: payload.payload.setIsDisabled,
          page: 1,
          limit: 2,
        })
      );
    } else {
      yield put(
        getApplicants({
          id: payload.payload.id,
          setLoader: payload.payload.setIsDisabled,
          page: 1,
          limit: 2,
        })
      );
    }
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchConfirmApplicants() {
  yield takeLatest(CONFIRM_APPLICANTS, ConfirmSaga);
}

function* StartJob({ payload }) {
  try {
    const { jobStatus, jobId, providerId, setLoader } = payload;
    let data = {
      jobStatus: jobStatus,
      jobId: jobId,
      providerId: providerId,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`job/updateJobStatus`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield call(jobByIdSaga, { payload: { id: jobId, setLoader } });
    toast.success(CapitalizeFirstLetter(response.data.message));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchStartJob() {
  yield takeLatest(START_JOB, StartJob);
}

function* postSagaLoghours({ payload }) {
  try {
    const { jobId, hours, minutes, setShowLogHours } = payload;
    let data = {
      jobId: jobId,
      hours: hours,
      minutes: minutes,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/logHours`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      yield put(getLogHoursSaga({ id: jobId }));
      setShowLogHours(false);
    }
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchPostLogHours() {
  yield takeLatest(POST_LOG_HOURS, postSagaLoghours);
}

function* getLogHoursSaga({ payload }) {
  try {
    const { id } = payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`job/getHours/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getLogHoursSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetLogHours() {
  yield takeLatest(GET_LOG_HOURS, getLogHoursSaga);
}
function* RateJobSaga({ payload }) {
  try {
    let Data = {
      description: payload.description,
      rating: payload.rating,
      jobId: payload.jobId,
      ratedBy: payload.ratedBy,
      ratedTo: payload.ratedTo,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/rating`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    yield put(getLogHoursSuccess(response.data.data));
    payload.setShow(false);

    yield call(jobByIdSaga, { payload: { id: payload.jobId } });
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}

function* ApprovedHoursSaga({ payload }) {
  try {
    let data = {
      id: payload.id,
      status: payload.status,
      providerId: payload.providerId,
    };

    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`job/approveHours`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    yield put(getApprovedHoursSuccess(response.data.data));
    yield put(
      getApplicantsByUserId({
        id: payload.jobId,
        page: payload.page,
        limit: payload.limit,
        usersId: payload.usersId,
      })
    );

    // window.location.reload()
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchApprovedLogHours() {
  yield takeLatest(APPROVED_LOG_HOURS, ApprovedHoursSaga);
}
function* getSingleSaga({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`profile/${payload.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getSingleUserSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetSingleUser() {
  yield takeLatest(GET_SINGLE_USER, getSingleSaga);
}
function* watchRateJob() {
  yield takeLatest(RATE_PROVIDER, RateJobSaga);
}

function* CompletejobSaga({ payload }) {
  try {
    let Data = {
      jobId: payload.jobId,
      userId: payload.userId,
      jobStatus: payload.jobStatus,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`job/completedByCustomer`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.setLoader(false);
    yield put(
      getHiredApplicants({
        id: payload.payload.id,
        page: payload.payload.page,
        limit: payload.payload.limit,
      })
    );
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchCompleteJob() {
  yield takeLatest(COMPLETE_JOB, CompletejobSaga);
}
function* ConfirmjobSaga({ payload }) {
  try {
    let Data = {
      jobId: payload.jobId,
      userId: payload.userId,
      isCompleted: payload.isCompleted,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`job/confirmByCustomer`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.setLoader(false);
    yield put(
      getHiredApplicants({
        id: payload.payload.id,
        page: payload.payload.page,
        limit: payload.payload.limit,
      })
    );
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchConfirmJob() {
  yield takeLatest(CONFIRM_JOB, ConfirmjobSaga);
}

function* ConfirmjobByWorkerSaga({ payload }) {
  try {
    let Data = {
      jobId: payload.jobId,
      userId: payload.userId,
      jobStatus: payload.jobStatus,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`job/confirmByWorker`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield call(jobByIdSaga, {
      payload: { id: payload.jobId, setLoader: payload.setLoader },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchCompleteJobByWorker() {
  yield takeLatest(CONFIRM_JOB_BY_WORKER, ConfirmjobByWorkerSaga);
}

function* getApplicantsByUserIdSaga(payload) {
  try {
    const { id, usersId, page, limit } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/getLogHours/${id}/${usersId}?page=${page}&count=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getLogHoursSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetApplicantsByUserId() {
  yield takeLatest(GET_APPLICANTS_BYUSERID, getApplicantsByUserIdSaga);
}
function* emergencyJobSaga({ payload }) {
  try {
    const data = {
      jobId: payload.id,
      userId: payload.userId,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`job/emergency`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.setShowDefaultEmergency(false);
    yield put(getJobListingSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchemergencyJob() {
  yield takeLatest(EMERGENCY_JOB, emergencyJobSaga);
}
function* sendOfferSaga({ payload }) {
  // let repost = payload.isPost;
  const formData = new FormData();
  formData.append("id", payload.id);
  formData.append("name", payload.name);
  formData.append("description", payload.description);
  formData.append("requirement", payload.requirement);
  formData.append("paymentType", payload.paymentType);
  formData.append("rate", payload.rate);
  formData.append("unit", payload.unit);
  formData.append("hours", payload.hours);
  formData.append("days", payload.days);
  formData.append("location", JSON.stringify([payload.location]));
  formData.append("longitude", payload.longitude);
  formData.append("latitude", payload.latitude);
  formData.append("noOfProviders", payload.noOfProviders);
  formData.append("toolsNeeded", payload.toolsNeeded);
  formData.append("experienceRequired", payload.experienceRequired);
  formData.append(
    "jobType",
    payload.paymentType === "fixed" ? null : payload.jobType
  );
  formData.append("jobPlace", payload.jobPlace);
  formData.append(
    "jobNature",
    payload.paymentType === "fixed" ? null : payload.jobNature
  );
  formData.append("startDate", JSON.stringify(payload.startDate));
  formData.append("endDate", JSON.stringify(payload.endDate));
  formData.append(
    "jobType",
    payload.isOngoing === "fixed" ? null : payload.isOngoing
  );
  formData.append("category", payload.category);
  formData.append("jobImg", payload.jobImg);
  // formData.append("existImg", payload.existImg);
  formData.append("isPost", payload.isPost);
  formData.append("isOffer", payload.isOffer);
  formData.append("serviceId", payload.serviceId);

  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/offer`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    toast.success(CapitalizeFirstLetter(response.data.message));
    payload.setReset();
    payload.setButtonDisabled(false);
    payload.history("/dashboard");
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchSendOfferSaga() {
  yield takeLatest(SEND_OFFER, sendOfferSaga);
}
function* ExtendTimeSaga({ payload }) {
  try {
    let Data = {
      serviceId: payload.jobId,
      endDate: payload.endDate,
    };
    const token = yield select(makeSelectAuthToken());
    const response = axios.post(`job/timeExtend`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    yield put(extendJobTimeSuccess(response.data.data));
    payload.showModal(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchExtendTimeSaga() {
  yield takeLatest(EXTEND_TIME, ExtendTimeSaga);
}
function* getExtendSaga() {
  try {
    // const { id, usersId, page, limit } = payload.payload;
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`job/timeExtend/confirm`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getExtendSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetExtend() {
  yield takeLatest(GET_EXTEND, getExtendSaga);
}
export default function* addJobSaga() {
  yield all([fork(watchAddJob)]);
  yield all([fork(watchCompleteJobByWorker)]);
  yield all([fork(watchStartJob)]);
  yield all([fork(watchConfirmHiring)]);
  yield all([fork(watchGetShortList)]);
  yield all([fork(watchAddShortList)]);
  yield all([fork(watchApplyJob)]);
  yield all([fork(watchAddWorkerJob)]);
  yield all([fork(watchGetJob)]);
  yield all([fork(watchGetFavouriteJob)]);
  yield all([fork(watchDeleteAddJob)]);
  yield all([fork(watchMarkAsFavouriteJob)]);
  yield all([fork(watchJobById)]);
  yield all([fork(watchGetGigs)]);
  yield all([fork(watchGetScheduleDays)]);
  yield all([fork(watchUpdateJob)]);
  yield all([fork(watchGetApplicants)]);
  yield all([fork(watchConfirmApplicants)]);
  yield all([fork(watchGetLogHours)]);
  yield all([fork(watchPostLogHours)]);
  yield all([fork(watchApprovedLogHours)]);
  yield all([fork(watchGetSingleUser)]);
  yield all([fork(watchRateJob)]);
  yield all([fork(watchGetHiredApplicants)]);
  yield all([fork(watchCompleteJob)]);
  yield all([fork(watchRejectedApplicants)]);
  yield all([fork(watchConfirmJob)]);
  yield all([fork(watchGetApplicantsByUserId)]);
  yield all([fork(watchemergencyJob)]);
  yield all([fork(watchSendOfferSaga)]);
  yield all([fork(watchExtendTimeSaga)]);
  yield all([fork(watchGetExtend)]);
}
