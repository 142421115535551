import React from "react";
import { Card, Image } from "@themesberg/react-bootstrap";
import { Link } from "react-router-dom";
import Profile from "../assets/img/team/profile.png";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";

const ProfileCard = (props) => {
  return (
    <Card border="light" className="shadow-sm selfCard">
      <div className="imgaSection">
        <Image
          src={props.img || Profile}
          className="navbar-brand-light"
          alt={props.name}
        />
        <span className="starSpan" style={{ cursor: "pointer" }}>
          <FontAwesomeIcon icon={faStar} /> {(props?.star * 20).toFixed(1)}
        </span>
      </div>
      <div className="d-block m-2">
        <Link to={`/detailProvider/${props.id}`}>
          <Typography variant="h6" className="text-dark">
            {props.name}
          </Typography>
          <Typography variant="subtitle1" style={{ color: "grey" }}>
            Complete Jobs: {props.completed}
          </Typography>
          <Typography variant="subtitle1" style={{ color: "grey" }}>
            Rank: {props.badge ? props.badge : "N/A"}
          </Typography>
        </Link>
      </div>
    </Card>
  );
};

export default ProfileCard;
