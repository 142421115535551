import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Form,
  Nav,
  Pagination,
  Row,
} from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CommonCard from "../../../components/CommonCard";
import Navbar from "../../../components/Navbar";
import NoRecordFound from "../../../components/NoRecordFound";
import Spinner from "../../../components/spinner";
import { Routers } from "../../../routes";
import { getGigs } from "../../../Redux/addJob/actions";

const currencies = [
  {
    value: "approved",
    label: "Approved",
  },
  {
    value: "pending",
    label: "Pending",
  },
];

const GigsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const JobList = useSelector((state) => state?.addJob?.getGigs);
  const [page, setPage] = useState(1);
  const [type, setType] = useState("approved");
  const [loader, setLoader] = useState(true);
  const [limit] = useState("10");
  const handleChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    dispatch(
      getGigs({
        search: "",
        page: page,
        limit: limit,
        type: type,
        setLoader: setLoader,
      })
    );
  }, [dispatch, page, limit, type]);

  const nextPage = () => {
    if (page < JobList?.pages) {
      setPage(page + 1);
    }
  };

  const previousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const paginationItems = () => {
    return Array.from({ length: JobList?.pages }, (_, number) => (
      <Pagination.Item
        key={number + 1}
        active={number + 1 === page}
        onClick={() => setPage(number + 1)}
      >
        {number + 1}
      </Pagination.Item>
    ));
  };

  return (
    <>
      <Navbar module={"My Gigs"} />
      <Row className="pt-2 pb-4">
        {loader ? (
          <Spinner />
        ) : (
          <>
            <div className="d-flex justify-content-between mt-0 mb-4 headerBorder">
              <Col lg={4} md={4}>
                <Form.Group>
                  <Form.Select
                    defaultValue="1"
                    label="Select"
                    value={type}
                    onChange={handleChange}
                  >
                    {currencies.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <span className="d-flex align-items-baseline mb-3">
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={() => navigate(Routers.CreateWrokerJob.path)}
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 12H8M12 8V12V8ZM12 12V16V12ZM12 12H16H12Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                      stroke="white"
                      strokeWidth="2"
                    />
                  </svg>
                  {"  "}
                  {"Add GIG"}
                </Button>
              </span>
            </div>
            {JobList?.data?.length > 0 ? (
              <>
                {JobList?.data.map((item) => (
                  <Col
                    lg={2}
                    md={4}
                    sm={6}
                    xs={12}
                    className="pb-3 mt-3"
                    key={item.id}
                  >
                    <CommonCard
                      img={item?.images}
                      name={item?.name || "N/A"}
                      id={item.id}
                      item={item}
                      rate={item?.rate || "N/A"}
                      completed={"90"}
                      star={item?.rating}
                      myJobs={true}
                      page={page}
                      job="job"
                      limit={limit}
                      type={type}
                      favourite={item.isFavourite}
                    />
                  </Col>
                ))}
                <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
                  <Nav>
                    <Pagination size={"sm"} className="mb-2 mb-lg-0">
                      <Pagination.Prev onClick={previousPage}>
                        <FontAwesomeIcon icon={faAngleDoubleLeft} />
                      </Pagination.Prev>
                      {paginationItems().length > 10 ? (
                        <>
                          {paginationItems().slice(0, 10)}
                          <Pagination.Ellipsis />
                          {paginationItems().slice(-10)}
                        </>
                      ) : (
                        paginationItems()
                      )}
                      <Pagination.Next onClick={nextPage}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} />
                      </Pagination.Next>
                    </Pagination>
                  </Nav>
                  <small className="fw-bold">
                    Showing <b>{JobList?.data?.length}</b> out of{" "}
                    <b>{JobList?.total_jobs}</b> entries
                  </small>
                </Card.Footer>
              </>
            ) : (
              <NoRecordFound />
            )}
          </>
        )}
      </Row>
    </>
  );
};

export default GigsList;
