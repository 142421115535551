import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Dropdown,
  ListGroup,
  Nav,
  Navbar,
  Row,
} from "@themesberg/react-bootstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { onMessageListener } from "../firebase";
import { getNotifiaction } from "../Redux/notification/actions";
import Spinner from "./spinner";
import Profile from "../assets/img/team/profile-picture-1.jpg";
import InfiniteScroll from "react-infinite-scroll-component";
import { Constant } from "../utils/constant";

/* eslint-disable */

export default (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if(SingleId !== undefined){
  //     id:SingleId?.id
  //   }
  // }, [SingleId])
  const [loader, setLoader] = useState();
  const [notificationData, setNotificationData] = useState({
    title: "",
    body: "",
  });
  const auth = useSelector((state) => state.auth.Auther);
  const notifications = useSelector(
    (state) => state.Notifications?.notification
  );
  const [notification, setNotification] = useState([]);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (notifications?.notifications) {
      setNotification(
        notifications.pages === 1 || page === 1
          ? [...notifications?.notifications]
          : [...notification, ...notifications?.notifications]
      );
    }
  }, [notifications, page]);

  const pageNumber = (pageNumber) => {
    if (notifications?.pages > page) {
      setPage(pageNumber + 1);
    }
  };
  //loadmore

  // useEffect(() => {
  //   setNotification(notifications?.notifications)
  // }, [notifications]);

  useEffect(() => {
    if (auth?.role?.name !== Constant.Role.ADMIN) {
      if (notificationData?.body !== "") {
        toast.success(notificationData?.body ? notificationData?.body : "");
      }
    } else {
      if (
        notificationData?.title === "Job Request" ||
        notificationData?.title === "Report User" ||
        notificationData?.title === "Disputed User" ||
        notificationData?.title === "Category User"
      ) {
        toast.success(notificationData?.body ? notificationData?.body : "");
      }
    }
  }, [notificationData, auth]);
  onMessageListener()
    .then((payload) => {
      setNotificationData({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  const notificationList = () => {
    dispatch(
      getNotifiaction({
        id: auth?.id,
        page: page,
        setLoader: setLoader,
      })
    );
  };
  const handleScroll = (e) => {
    window.height = 400;
  };

  const handleRedirection = (jobs, users, title) => {
    if (
      title === "provider appply for job" ||
      title === "provider confirm the job"
    ) {
      navigate(`/Applicants/${jobs.id}`);
    }
    if (
      title === "JOB_COMPLETED_BY_PROVIDER" ||
      title === "Job Status" ||
      title === "Logged Hours" ||
      title === "job started" ||
      title === "job canceled"
    ) {
      navigate(`/detailJob/${jobs.id}`);
    }
    if (title === "Logged Hours" || title === "Hours Approval request") {
      navigate(`/LogHours/${jobs.id}`);
    }
    if (
      title === "Location Shared" ||
      title === "Location Updated" ||
      title === "Job Applied" ||
      title === "Job Completed" ||
      title === "job completed" ||
      title === "Job is Confirmed"
    ) {
      navigate(`/Applicants/${jobs.id}`);
    }
    if (title.slice(0, 12) === "Message Sent") {
      navigate(`/chat?${users?.id}?${users?.firebaseId}`);
    }
    if (title === "Category User") {
      navigate(`/category-management`);
    }
    if (title === "Emergency Job Post" || title === "Job Request") {
      navigate(`/Job-management`);
    }
    if (title === "Disputed User") {
      navigate(`/dispute-management`);
    }
    if (title === "Report User") {
      navigate(`/Report-management`);
    }
  };
  const Notification = (props) => {
    const { title, insertedDate, message, read = false, jobs, users } = props;
    const readClassName = read ? "" : "text-danger";
    return (
      <ListGroup.Item
        action
        onClick={() => handleRedirection(jobs, users, title)}
        className="border-bottom border-light"
      >
        <Row className="align-items-center">
          {title.slice(0, 12) === "Message Sent" ? (
            <Col className="col-auto">
              <img
                src={users?.profileImg ? users?.profileImg : Profile}
                alt=""
                className="user-avatar lg-avatar rounded-circle"
              />
            </Col>
          ) : (
            <Col className="col-auto">
              <img
                src={jobs?.image ? jobs?.image : Profile}
                alt=""
                className="user-avatar lg-avatar rounded-circle"
              />
            </Col>
          )}
          <Col className="ps-0 ms--2 mx-3">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">
                  {title ? title : "Title"}
                </h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>
                  {insertedDate
                    ? moment(insertedDate).format("MM/DD/YYYY hh:mm")
                    : "date"}
                </small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">
              {message ? message : "messages"}
            </p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };

  return (
    <Navbar variant="dark" expanded className="mb-3">
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center">
          <h2>{props?.module}</h2>
        </div>
        {auth?.role?.name && (
          <Nav className="align-items-center" onScroll={handleScroll}>
            {window?.location?.pathname !== "/privacy-public" &&
              window?.location?.pathname !== "/terms-public" && (
                <>
                  <Dropdown as={Nav.Item}>
                    <Dropdown.Toggle
                      as={Nav.Link}
                      className="text-primary icon-notifications me-lg-3"
                    >
                      <span className="icon icon-sm" onClick={notificationList}>
                        <FontAwesomeIcon icon={faBell} className="bell-shake" />
                        {/* {notification?.length > 0 && (
                      <span className="icon-badge rounded-circle unread-notifications" />
                    )} */}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                      <ListGroup className="list-group-flush notification-list">
                        <Nav.Link
                          href="#"
                          className="text-center text-primary fw-bold border-bottom border-light py-3"
                        >
                          Notifications
                        </Nav.Link>

                        {loader ? (
                          <Spinner />
                        ) : (
                          <InfiniteScroll
                            dataLength={notification?.length || 0}
                            hasMore={notifications?.pages > page}
                            loader={<Spinner />}
                            next={() => pageNumber(page)}
                            scrollableTarget="scrollableDiv"
                          >
                            {notification?.map((n) => (
                              <Notification
                                key={`notification-${n.id}`}
                                {...n}
                              />
                            ))}
                          </InfiniteScroll>
                        )}

                        {/* <Dropdown.Item className="text-center text-primary fw-bold py-3">
                        View all
                      </Dropdown.Item> */}
                      </ListGroup>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <FontAwesomeIcon icon={faSun} className="bell-shake" />
                <FontAwesomeIcon icon={faMoon} className="bell-shake" /> */}
                </>
              )}
          </Nav>
        )}
      </div>
    </Navbar>
  );
};
