import { toast } from "react-toastify";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../Routes/axiosConfig";
import { sagaErrorHandler } from "../../Shared/shared";
import { makeSelectAuthToken } from "../../Store/selector";
import { logoutRequest } from "../auth/actions";
import {
  getJobListing,
  getJobListingSuccess,
  getJobEventsSuccess,
} from "./actions";
import {
  GET_JOB_LISTING,
  DELETE_JOB,
  ACTION_JOB,
  GET_JOB_EVENTS,
} from "./constants";
import { CapitalizeFirstLetter } from "../../utils/Global";

function* getJobList({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(
      `job/admin/?page=${payload.page}&count=${payload.limit}&status=${
        payload.status
      }&search=${payload.search}&type=${payload.type}&category=${
        payload.category ? payload.category : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    yield put(getJobListingSuccess(response.data.data));
    payload.setLoader(false);
  } catch (error) {
    payload.setLoader(false);
    if (error?.response?.status === 401) {
      yield put(logoutRequest());
    }
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetJob() {
  yield takeLatest(GET_JOB_LISTING, getJobList);
}

function* deleteJob({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.delete(`job/admin/${payload.jobId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let data = {
      page: payload.page,
      limit: payload.limit,
      status: payload.status,
      type: payload.type,
      search: payload.search,
      category: payload.category,
    };
    toast.success(CapitalizeFirstLetter(response.data.message));
    yield put(getJobListing(data));
    yield put(getJobListingSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchDeleteJob() {
  yield takeLatest(DELETE_JOB, deleteJob);
}
function* changeJobStatusSaga({ payload }) {
  try {
    let data = {
      id: payload.id,
      isApproved: payload.isApproved,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/admin/approve-request`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(CapitalizeFirstLetter(response.data.message));
    // yield put(getJobListingSuccess(response.data.data));
    yield put(
      getJobListing({
        page: payload.page,
        limit: payload.limit,
        status: payload.status,
        type: payload.type,
        search: payload.search,
        category: payload.category,
        setLoader: payload.setLoader,
      })
    );
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchchangeJobStatus() {
  yield takeLatest(ACTION_JOB, changeJobStatusSaga);
}

function* getJobEvents({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`job/events/${payload.userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(getJobEventsSuccess(response.data.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(logoutRequest());
    }
    yield sagaErrorHandler(error.response);
  }
}

function* watchGetJobEvents() {
  yield takeLatest(GET_JOB_EVENTS, getJobEvents);
}

export default function* JobManagementSaga() {
  yield all([fork(watchGetJob)]);
  yield all([fork(watchDeleteJob)]);
  yield all([fork(watchchangeJobStatus)]);
  yield all([fork(watchGetJobEvents)]);
}
