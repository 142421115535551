import { Button, Card, Col, Row, Table } from "@themesberg/react-bootstrap";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Profile1 from "../../assets/img/team/profile-picture-1.jpg";
import DetailHeading from "../../components/DetailHeading";
import Navbar from "../../components/Navbar";
import { Routers } from "../../routes";
// saga actions here
import moment from "moment";
import { Rating } from "react-simple-star-rating";
import { getProfile, getReviews } from "../../Redux/profile/actions";
import NoRecordFound from "../../components/NoRecordFound";
// import RecommendCard from "../../components/RecommendCard";
// import { TextField } from "@mui/material";
import ReactPlayer from "react-player";
import { toast } from "react-toastify";
import Spinner from "../../components/spinner";
import { memo } from "react";

const Profile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isWorker, setIsWorker] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Combine selectors
  const {
    auth: { Auther: login },
    ProfileReducer: { profile: getById, Reviews },
  } = useSelector((state) => ({
    auth: state.auth,
    ProfileReducer: state.ProfileReducer,
  }));

  // Combine related useEffects
  useEffect(() => {
    if (login?.id) {
      dispatch(getProfile({ profileId: login.id, setIsLoading }));
      dispatch(getReviews({ userId: login.id }));
    }
  }, [dispatch, login?.id]);

  useEffect(() => {
    setIsWorker(getById?.role?.name === "worker");
  }, [getById?.role?.name]);

  // Memoize handler functions
  const handleEditProfile = useCallback(() => {
    if (login?.isApproved) {
      navigate(Routers.EditProfile.path);
    } else {
      toast.error("Your profile is pending from admin side");
    }
  }, [login?.isApproved, navigate]);

  // Extract reusable components
  const ProfileImage = () => (
    <Card.Img
      src={getById?.profileImg ?? Profile1}
      alt="Profile"
      className="user-avatar large-avatar rounded-circle mx-auto mb-2"
    />
  );

  const ReviewCard = ({ item }) => (
    <Col xs={12} className="pb-3">
      <Card border="light" className="shadow-sm introCard">
        <div className="detailSection">
          <span className="left">
            <h3>
              <span>{item?.jobs?.name}-</span>
            </h3>
            <Rating
              style={{ marginTop: "-7%" }}
              size={20}
              readonly={true}
              allowHover={false}
              initialValue={item?.rating ? item?.rating * 20 : "0"}
            />
            <h5>{item?.description}</h5>
          </span>
          <span className="right p-2">
            <h6>{item?.jobs?.paymentType}</h6>
            <h6>
              {item?.insertedDate
                ? moment(item?.insertedDate).format("DD-MM-YYYY")
                : " --"}
            </h6>
            <h6>
              Rate: <span>${item?.jobs?.rate}hr</span>
            </h6>
          </span>
        </div>
      </Card>
    </Col>
  );

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Navbar module={"Profile"} />
          <Row>
            <div className="mt-2 mb-3 d-flex justify-content-end">
              <Button
                variant="primary"
                type="submit"
                onClick={handleEditProfile}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 17.0129L11.413 16.9979L21.045 7.4579C21.423 7.0799 21.631 6.5779 21.631 6.0439C21.631 5.5099 21.423 5.0079 21.045 4.6299L19.459 3.0439C18.703 2.2879 17.384 2.2919 16.634 3.0409L7 12.5829V17.0129ZM18.045 4.4579L19.634 6.0409L18.037 7.6229L16.451 6.0379L18.045 4.4579ZM9 13.4169L15.03 7.4439L16.616 9.0299L10.587 15.0009L9 15.0059V13.4169Z"
                    fill="white"
                  />
                  <path
                    d="M5 21H19C20.103 21 21 20.103 21 19V10.332L19 12.332V19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21Z"
                    fill="white"
                  />
                </svg>
                {"  "}
                Edit Profile
              </Button>
            </div>
            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-center p-0 mb-4 profileView"
                  >
                    <Card.Body className="pb-2">
                      <ProfileImage />

                      <div className="border_bottom pb-3 mb-4">
                        <Card.Title>{getById?.businessName}</Card.Title>
                        <Card.Subtitle className="fw-normal capt">
                          {getById?.role?.name}
                        </Card.Subtitle>
                        <Card.Text className="text-gray mb-2">
                          Overall Rating
                        </Card.Text>
                        <Rating
                          // onClick={handleRating}
                          readonly={true}
                          allowHover={false}
                          initialValue={
                            getById?.profile_rating
                              ? getById?.profile_rating * 20
                              : "0"
                          } /* Available Props */
                        />
                        <Card.Text className="text-gray mb-0">
                          {" "}
                          Jobs Completed: <b>{getById?.completeJobs}</b>
                        </Card.Text>
                      </div>
                    </Card.Body>
                  </Card>
                  <Card
                    border="light"
                    className="card-box-shadow py-1 px-4 mb-2 job-list"
                  >
                    <h3 className="mb-3 mt-2 text-center">Intro Video</h3>
                    {getById?.videoUrl ? (
                      <ReactPlayer
                        url={getById?.videoUrl}
                        controls
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <NoRecordFound />
                    )}
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={8}>
              <Row>
                <Col xs={12}>
                  <Card
                    border="light"
                    className="text-left p-0 mb-4 profileView info"
                  >
                    <Card.Body className="pb-3">
                      <div className="border_bottom pb-2 mb-4">
                        <Card.Title className="text-primary">
                          Basic Information
                        </Card.Title>
                        <DetailHeading
                          heading={"Full Name"}
                          value={getById?.fullName}
                        />
                        <DetailHeading
                          heading={"Member Since"}
                          value={
                            getById?.memberSince
                              ? moment(getById?.memberSince).format(
                                  "DD-MM-YYYY"
                                )
                              : " "
                          }
                        />
                        <DetailHeading
                          heading={"Date Of Birth"}
                          value={
                            getById?.memberSince
                              ? moment(getById?.dateOfBirth).format(
                                  "DD-MM-YYYY"
                                )
                              : " "
                          }
                        />
                        {isWorker && (
                          <DetailHeading
                            heading={"Language"}
                            value={
                              getById?.language
                                ? getById?.language
                                    .map((language) => language.name)
                                    .join(", ")
                                : ""
                            }
                          />
                        )}
                      </div>
                      <div className="border_bottom pb-2 mb-4">
                        <Card.Title className="text-primary">
                          Contact Information
                        </Card.Title>
                        <DetailHeading
                          heading={"Email"}
                          value={getById?.email}
                        />
                        <DetailHeading
                          heading={"Phone"}
                          value={getById?.phoneNumber}
                        />
                        <DetailHeading
                          heading={"Address"}
                          value={getById?.address}
                        />
                        {!isWorker && (
                          <DetailHeading
                            heading={"IndustryType"}
                            value={
                              getById?.industryType
                                ? getById?.industryType
                                : "N/A"
                            }
                          />
                        )}
                        <DetailHeading
                          heading={"Postal Code"}
                          value={getById?.postalCode}
                        />
                        <DetailHeading heading={"City"} value={getById?.city} />
                        {isWorker && (
                          <DetailHeading
                            heading={"Province"}
                            value={getById?.province}
                          />
                        )}
                      </div>
                      {isWorker && (
                        <div className="border_bottom pb-2 mb-4">
                          <Card.Title className="text-primary">
                            Worker Profile Details
                          </Card.Title>
                          <DetailHeading
                            heading={"Profile Type"}
                            value={getById?.profileType}
                          />
                          <DetailHeading heading={"Work Experience"} />
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Job Title</th>
                                <th>Details</th>
                                <th>Employment Type</th>
                                <th>Location</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getById?.workExperience.map(
                                (formData, index) => (
                                  <tr key={index}>
                                    <td>{formData.jobTitle}</td>
                                    <td>{formData.details}</td>
                                    <td>{formData.employement}</td>
                                    <td
                                      style={{
                                        wordWrap: "break-word",
                                        whiteSpace: "normal",
                                      }}
                                    >
                                      {formData.location}
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                          <DetailHeading heading={"Achievements"} />
                          <Table striped bordered hover>
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>Details</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getById?.achievements.map((formData, index) => (
                                <tr key={index}>
                                  <td>{formData.title}</td>
                                  <td>{formData.details}</td>
                                  <td>
                                    {moment(formData.startDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                  <td>
                                    {moment(formData.endDate).format(
                                      "YYYY-MM-DD"
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      )}
                      <div className="pb-2 mb-4">
                        <Row></Row>
                      </div>
                      <Card
                        border="light"
                        className="card-box-shadow py-1 px-4 mb-2 job-list"
                      >
                        <h3 className="mb-3 mt-2 text-start">
                          {" "}
                          <Card.Title className="text-primary">
                            Reviews
                          </Card.Title>
                        </h3>
                        {Reviews?.length > 0 ? (
                          <>
                            {Reviews?.map((item) => {
                              return <ReviewCard key={item.id} item={item} />;
                            })}
                          </>
                        ) : (
                          <NoRecordFound />
                        )}
                      </Card>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default memo(Profile);
