import { Button, Modal, Form } from "@themesberg/react-bootstrap";
import { useDispatch } from "react-redux";
import { applyJob } from "../../../Redux/addJob/actions";
import { Formik } from "formik";
import * as Yup from "yup";

const ApplyModal = ({
  setApply,
  apply,
  jobId,
  questions,
  rate,
  paymentType,
  setLoader,
  loader,
}) => {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    proposalDescription: Yup.string()
      .required("Proposal description is required")
      .min(50, "Proposal should be at least 50 characters"),
    rate: Yup.number()
      .required("Rate is required")
      .min(1, "Rate must be greater than 0"),
    answers: Yup.array().of(
      Yup.object().shape({
        questionId: Yup.string().required(),
        answer: Yup.string().required("Answer is required"),
      })
    ),
  });

  const initialValues = {
    proposalDescription: "",
    rate: rate,
    answers: questions
      ? questions.map((q) => ({
          questionId: q.id, // Assuming each question has an _id field
          answer: "",
        }))
      : [],
  };
  return (
    <Modal
      as={Modal.Dialog}
      centered
      show={apply}
      onHide={() => setApply(false)}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title className="h5">Apply for Job</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setLoader(true)
          dispatch(
            applyJob({
              jobId: jobId,
              setApply: setApply,
              proposalDescription: values.proposalDescription,
              rate: values.rate,
              answers: values.answers,
              setLoader: setLoader,
            })
          );
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body className="px-4">
              <Form.Group className="mb-3">
                <Form.Label>Proposal Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="proposalDescription"
                  value={values.proposalDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.proposalDescription && errors.proposalDescription
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {errors.proposalDescription}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{`${
                  paymentType.charAt(0).toUpperCase() + paymentType.slice(1)
                } $`}</Form.Label>
                <Form.Control
                  type="number"
                  name="rate"
                  value={values.rate ?? rate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.rate && errors.rate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rate}
                </Form.Control.Feedback>
              </Form.Group>

              {questions &&
                questions.map((question, index) => (
                  <Form.Group key={question.id} className="mb-3">
                    <Form.Label>{question.name}</Form.Label>
                    <Form.Control
                      type="text"
                      name={`answers.${index}.answer`}
                      value={values.answers[index].answer}
                      onChange={(e) => {
                        setFieldValue(`answers.${index}`, {
                          questionId: question.id,
                          answer: e.target.value,
                        });
                      }}
                      onBlur={handleBlur}
                      isInvalid={
                        touched.answers?.[index]?.answer &&
                        errors.answers?.[index]?.answer
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.answers?.[index]?.answer}
                    </Form.Control.Feedback>
                  </Form.Group>
                ))}

              <div className="d-flex justify-content-between mt-4">
                <Button
                  variant="white"
                  className="shadow"
                  onClick={() => setApply(false)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" disabled={loader}>
                  Submit Application
                </Button>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ApplyModal;
