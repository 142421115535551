import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { Constant } from "../utils/constant";

const PublicRoute = () => {
  const userDetail = useSelector((state) => state.auth);
  if (
    userDetail?.token &&
    userDetail?.isEmailVerified &&
    userDetail?.isCompleteProfile
  ) {
    return (
      <Navigate
        to={
          userDetail?.Auther?.role?.name === Constant.Role.ADMIN
            ? "/adminDashBoard"
            : "/dashboard"
        }
      />
    );
  } else {
    return <Outlet />;
  }
};

export default PublicRoute;
