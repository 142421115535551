import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import BankConnect from "./pages/payementMethod/connectBankAccount";
import ViewProfile from "./components/viewProfile";
import ProviderProfile from "./pages/profile/providerProfile";
import { Routers } from "./routes";
import PrivateRoute from "./Routes/PrivateRoute";
import SubCategories from "./pages/SubCategories/SubCategories";
import Wallet from "./pages/payementMethod/wallet";
import PublicRoute from "./Routes/PublicRoute";
import Categories from "./pages/category/categories";
import JobManagement from "./pages/Job Management/jobManagement";
import CategoryManagement from "./pages/category/categoryManagement";
import DashboardOverview from "./pages/dashboard/DashboardOverview";
import NewArrivalProvider from "./pages/newArrivalProvider/newArrivalProvider";
import TopRatedProviders from "./pages/newArrivalSeekers/newArrivalSeekers";
import Recommend from "./pages/recommended/recommended";
import ForgotPassword from "./pages/auths/ForgotPassword";
import NotFoundPage from "./pages/auths/NotFound";
import ResetPassword from "./pages/auths/ResetPassword";
import Signin from "./pages/auths/Signin";
import Favourites from "./pages/favourite/favourites";
import CreateJob from "./pages/jobs/CreateJob";
import CreateWorkerJob from "./pages/jobs/workerJob/index";
import Applicants from "./pages/jobs/applicants";
import LogHourDetails from "./pages/jobs/logHourDetails";
import LogHours from "./pages/jobs/logHours";
import ExtendTime from "./pages/jobs/extendTime";
import Job from "./pages/jobs/Job";
import Search from "./pages/Search";
import CreateProfile from "./pages/profile/CreateProfile";
import EditProfile from "./pages/profile/UpdateProfile";
import Profile from "./pages/profile/Profile";
import UserDetail from "./pages/user/userDetail";
import JobDetails from "./pages/Job Management/jobDetails";
import UserManagement from "./pages/user/userManagement";
import Users from "./pages/user/users";
import EditAdminProfile from "./pages/AdminProfile/editAdminProfile";
import AdminProfile from "./pages/AdminProfile/adminProfile";
import MyJobDetails from "./pages/jobs/myJobDetails";
import BusinessCategories from "./pages/businessCategory/index";
import BusinessSubCategory from "./pages/businessSubCategory/index";
import AdminDashBoard from "./pages/AdminPanelDashboard/adminDashBoard";
import Settings from "./pages/Settings/index";
import Chat from "./pages/Chat-New/main";
import ReportManagement from "./pages/Report Management";
import ChangePassword from "./components/changePassword";
import TermsandConditions from "./pages/Settings/TermsandConditions";
import PrivacyPolicy from "./pages/Settings/PrivacyPolicy";
import PayementMethod from "./pages/payementMethod";
import AddCard from "./pages/payementMethod/addCard";
import WorkExperience from "./pages/WorkExperience";
import Achievement from "./pages/Achievement";
import DisputeManagement from "./pages/Dispute Management";
import { useSelector } from "react-redux";
import { Constant } from "./utils/constant";
import SignUp from "./pages/auths/Signup";
import VerifyOTP from "./pages/auths/verifyOtp";
import { Navigate } from "react-router-dom";
import Events from "./pages/Events/Events";
import GigsList from "./pages/jobs/workerJob/gigsList";
import ContactUs from "./pages/Settings/contactus";

const App = () => {
  const userDetail = useSelector((state) => state.auth);
  return (
    <div style={{ minHeight: "100vh" }}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={Routers.ForgotPassword.path}
            element={<PublicRoute />}
          >
            <Route
              exact
              path={Routers.ForgotPassword.path}
              element={<ForgotPassword />}
            />
          </Route>
          <Route
            exact
            path={Routers.ResetPassword.path}
            element={<PublicRoute />}
          >
            <Route
              exact
              path={Routers.ResetPassword.path}
              element={<ResetPassword />}
            />
          </Route>
          <Route
            exact
            path={Routers.CreateProfile.path}
            element={<PublicRoute />}
          >
            <Route
              exact
              path={Routers.CreateProfile.path}
              element={
                userDetail?.token ? (
                  <CreateProfile />
                ) : (
                  <Navigate to="/" replace />
                )
              }
            />
          </Route>
          <Route
            path={Routers.DashboardOverview.path}
            element={<PrivateRoute />}
          >
            <Route
              path={Routers.DashboardOverview.path}
              element={
                userDetail?.Auther?.role?.name === Constant.Role.ADMIN ? (
                  <AdminDashBoard />
                ) : (
                  <DashboardOverview />
                )
              }
            />
          </Route>
          <Route path={Routers.Signin.path} element={<PublicRoute />}>
            <Route path={Routers.Signin.path} element={<Signin />} />
          </Route>
          <Route path={Routers.Contactus.path} element={<PrivateRoute />}>
            <Route path={Routers.Contactus.path} element={<ContactUs />} />
          </Route>
          <Route path={Routers.SignUp.path} element={<PublicRoute />}>
            <Route path={Routers.SignUp.path} element={<SignUp />} />
          </Route>
          <Route path={Routers.Verify_OTP.path} element={<PublicRoute />}>
            <Route path={Routers.Verify_OTP.path} element={<VerifyOTP />} />
          </Route>
          <Route
            exact
            path={Routers.NewArrivalProvider.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.NewArrivalProvider.path}
              element={<NewArrivalProvider />}
            />
          </Route>
          <Route
            exact
            path={Routers.TopRatedProviders.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.TopRatedProviders.path}
              element={<TopRatedProviders />}
            />
          </Route>
          <Route
            exact
            path={Routers.Recommended.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Recommended.path}
              element={<Recommend />}
            />
          </Route>

          <Route
            exact
            path={Routers.Favourites.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Favourites.path}
              element={<Favourites />}
            />
          </Route>
          <Route exact path={Routers.CreateJob.path} element={<PrivateRoute />}>
            <Route
              exact
              path={Routers.CreateJob.path}
              element={<CreateJob />}
            />
          </Route>
          <Route
            exact
            path={Routers.CreateWrokerJob.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.CreateWrokerJob.path}
              element={<CreateWorkerJob />}
            />
          </Route>
          <Route exact path={Routers.UpdateJob.path} element={<PrivateRoute />}>
            <Route
              exact
              path={Routers.UpdateJob.path}
              element={<CreateJob />}
            />
          </Route>
          <Route exact path={Routers.UpdateGig.path} element={<PrivateRoute />}>
            <Route
              exact
              path={Routers.UpdateGig.path}
              element={<CreateWorkerJob />}
            />
          </Route>
          <Route
            exact
            path={Routers.Applicants.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Applicants.path}
              element={<Applicants />}
            />
          </Route>
          <Route
            exact
            path={Routers.LogHoursDetails.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.LogHoursDetails.path}
              element={<LogHourDetails />}
            />
          </Route>
          <Route exact path={Routers.LogHours.path} element={<PrivateRoute />}>
            <Route exact path={Routers.LogHours.path} element={<LogHours />} />
          </Route>
          <Route
            exact
            path={Routers.ExtendTime.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.ExtendTime.path}
              element={<ExtendTime />}
            />
          </Route>
          <Route exact path={Routers.Search.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Search.path} element={<Search />} />
          </Route>
          <Route exact path={Routers.Job.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Job.path} element={<Job />} />
          </Route>
          <Route exact path={Routers.Gigs.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Gigs.path} element={<GigsList />} />
          </Route>
          <Route
            exact
            path={Routers.EditProfile.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.EditProfile.path}
              element={<EditProfile />}
            />
          </Route>
          <Route exact path={Routers.Profile.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Profile.path} element={<Profile />} />
          </Route>
          <Route
            exact
            path={Routers.Job_Management.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Job_Management.path}
              element={<JobManagement />}
            />
          </Route>
          <Route
            exact
            path={Routers.UserDetail.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.UserDetail.path}
              element={<UserDetail />}
            />
          </Route>
          <Route
            exact
            path={Routers.JobDetails.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.JobDetails.path}
              element={<JobDetails />}
            />
          </Route>
          <Route
            exact
            path={Routers.UserManagement.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.UserManagement.path}
              element={<UserManagement />}
            />
          </Route>
          <Route exact path={Routers.Users.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Users.path} element={<Users />} />
          </Route>
          <Route
            exact
            path={Routers.EditAdminProfile.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.EditAdminProfile.path}
              element={<EditAdminProfile />}
            />
          </Route>
          <Route
            exact
            path={Routers.AdminProfile.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.AdminProfile.path}
              element={<AdminProfile />}
            />
          </Route>
          <Route
            exact
            path={Routers.Categories.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Categories.path}
              element={<Categories />}
            />
          </Route>
          <Route
            exact
            path={Routers.Categories_Management.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Categories_Management.path}
              element={<CategoryManagement />}
            />
          </Route>

          <Route exact path={Routers.DetailJob.path} element={<PrivateRoute />}>
            <Route
              exact
              path={Routers.DetailJob.path}
              element={<MyJobDetails />}
            />
          </Route>
          <Route
            exact
            path={Routers.MyJobDetail.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.MyJobDetail.path}
              element={<MyJobDetails />}
            />
          </Route>
          <Route
            exact
            path={Routers.DetailProvider.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.DetailProvider.path}
              element={<ProviderProfile />}
            />
          </Route>

          <Route
            exact
            path={Routers.BusinessCategory.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.BusinessCategory.path}
              element={<BusinessCategories />}
            />
          </Route>
          <Route
            exact
            path={Routers.BusinessSubCategory.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.BusinessSubCategory.path}
              element={<BusinessSubCategory />}
            />
          </Route>
          <Route
            exact
            path={Routers.AdminDashBoard.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.AdminDashBoard.path}
              element={<AdminDashBoard />}
            />
          </Route>
          <Route exact path={Routers.Settings.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Settings.path} element={<Settings />} />
          </Route>
          <Route exact path={Routers.Chat.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Chat.path} element={<Chat />} />
          </Route>
          <Route
            exact
            path={Routers.Report_Management.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Report_Management.path}
              element={<ReportManagement />}
            />
          </Route>
          <Route
            exact
            path={Routers.ChangePassword.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.ChangePassword.path}
              element={<ChangePassword />}
            />
          </Route>
          <Route
            exact
            path={Routers.TermsandConditionsPublic.path}
            element={userDetail?.token ? <PrivateRoute /> : <PublicRoute />}
          >
            <Route
              exact
              path={Routers.TermsandConditionsPublic.path}
              element={<TermsandConditions />}
            />
          </Route>
          <Route
            exact
            path={Routers.PrivacyPolicyPublic.path}
            element={userDetail?.token ? <PrivateRoute /> : <PublicRoute />}
          >
            <Route
              exact
              path={Routers.PrivacyPolicyPublic.path}
              element={<PrivacyPolicy />}
            />
          </Route>
          <Route
            exact
            path={Routers.PayementMethod.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.PayementMethod.path}
              element={<PayementMethod />}
            />
          </Route>
          <Route exact path={Routers.AddCard.path} element={<PrivateRoute />}>
            <Route exact path={Routers.AddCard.path} element={<AddCard />} />
          </Route>
          <Route exact path={Routers.Wallet.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Wallet.path} element={<Wallet />} />
          </Route>
          <Route
            exact
            path={Routers.SubCategories.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.SubCategories.path}
              element={<SubCategories />}
            />
          </Route>
          <Route
            exact
            path={Routers.SubCategories.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.SubCategories.path}
              element={<ViewProfile />}
            />
          </Route>
          {/* <Route
            exact
            path={Routers.BankConnect.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.BankConnect.path}
              element={<BankConnect />}
            />
          </Route> */}

          <Route
            exact
            path={Routers.WorkExperience.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.WorkExperience.path}
              element={<WorkExperience />}
            />
          </Route>
          <Route
            exact
            path={Routers.Achievement.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Achievement.path}
              element={<Achievement />}
            />
          </Route>
          <Route
            exact
            path={Routers.Dispute_Management.path}
            element={<PrivateRoute />}
          >
            <Route
              exact
              path={Routers.Dispute_Management.path}
              element={<DisputeManagement />}
            />
          </Route>
          <Route exact path={Routers.Events.path} element={<PrivateRoute />}>
            <Route exact path={Routers.Events.path} element={<Events />} />
          </Route>
          <Route path={Routers.NotFound.path} element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
