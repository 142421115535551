import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Image,
  Nav,
  Pagination,
  Row,
} from "@themesberg/react-bootstrap";
import { Typography } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import PropTypes from "prop-types";

import NoRecordFound from "../../components/NoRecordFound";
import Spinner from "../../components/spinner";
import {
  getApplicants,
  getConfirmApplicants,
  shortListApplicant,
} from "../../Redux/addJob/actions";

const ITEMS_PER_PAGE = 5;
const MAX_VISIBLE_PAGES = 10;

// Styles
const buttonStyle = {
  base: {
    width: "100px",
    height: "40px",
    display: "inline-block",
    marginRight: "10px",
  },
  green: {
    background: "green",
  },
};

const cardStyle = {
  marginTop: "15px",
};

// PropTypes
const applicantPropTypes = {
  item: PropTypes.shape({
    user: PropTypes.shape({
      profileImg: PropTypes.string,
      fullName: PropTypes.string,
      profile_rating: PropTypes.number,
      compltedJobs: PropTypes.number,
    }),
    description: PropTypes.string,
    answers: PropTypes.array,
    acceptedBySeeker: PropTypes.bool,
  }).isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleRating: PropTypes.func.isRequired,
};

// Error Boundary
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

// Components
const ApplicantCard = ({ item, handleConfirm, handleRating }) => (
  <Card border="light" className="shadow-sm cardShadow" style={cardStyle}>
    <div className="applicantCard">
      <Image
        src={item?.user?.profileImg || ""}
        className="navbar-brand-light"
        alt={`${item?.user?.fullName}'s profile`}
      />
      <ApplicantDetails item={item} handleRating={handleRating} />
    </div>
    <ActionButtons item={item} handleConfirm={handleConfirm} />
  </Card>
);

ApplicantCard.propTypes = applicantPropTypes;

const ApplicantDetails = ({ item, handleRating }) => (
  <div className="detailSection">
    <span className="left">
      <UserInfo user={item.user} handleRating={handleRating} />
      <ProposalSection description={item.description} />
      <QuestionsSection answers={item.answers} />
    </span>
  </div>
);

const UserInfo = ({ user, handleRating }) => (
  <>
    <Typography style={{ marginBottom: 5 }}>
      <a href={`/detailProvider/${user?.id}`}>view profile</a>
    </Typography>
    <hr />
    <Typography color="textPrimary" component="h2">
      <strong>User Inormation</strong>
    </Typography>
    <Typography color="textPrimary" component="h2">
      <strong>Name:</strong> {user?.fullName}
    </Typography>
    <Typography color="textPrimary" component="h2">
      <strong>Rating:</strong>
      <Rating
        readonly={true}
        allowHover={false}
        size={25}
        onClick={handleRating}
        initialValue={user?.profile_rating ? user.profile_rating * 20 : 0}
      />
    </Typography>
    <Typography color="textPrimary" component="h2">
      <strong>Jobs Completed:</strong> {user?.compltedJobs ?? "5"}
    </Typography>
  </>
);

const ProposalSection = ({ description }) => (
  <>
    <hr />
    <Typography
      color="textPrimary"
      component="h2"
      style={{ fontWeight: "bold" }}
    >
      Proposal Description:
    </Typography>
    <Typography color="textSecondary" component="p">
      {description}
    </Typography>
  </>
);

const QuestionsSection = ({ answers = [] }) => (
  <>
    <hr />
    <Typography
      color="textPrimary"
      component="h2"
      style={{ fontWeight: "bold" }}
    >
      Questions
    </Typography>
    {answers.length > 0 ? (
      answers.map((answer, index) => (
        <div key={index}>
          <Typography
            color="textPrimary"
            component="h2"
            style={{ fontWeight: "bold" }}
          >
            {index + 1}. {answer.name}
          </Typography>
          <Typography variant="body1" color="textSecondary" component="p">
            {answer.answers[0].answers}
          </Typography>
        </div>
      ))
    ) : (
      <Typography variant="body1" color="textSecondary" component="p">
        No answers available
      </Typography>
    )}
  </>
);

const ActionButtons = ({ item, handleConfirm }) => {
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <div style={{ display: "flex", padding: "10px" }}>
      {item?.acceptedBySeeker === null ? (
        <>
          <Button
            variant="primary"
            size="sm"
            style={buttonStyle.base}
            onClick={() =>
              handleConfirm({
                id: item,
                isAccepted: true,
                setIsDisabled: setIsDisabled,
                isDisabled: isDisabled,
              })
            }
            disabled={isDisabled}
          >
            Accept
          </Button>
          <Button
            variant="danger"
            size="sm"
            style={{ ...buttonStyle.base, ...buttonStyle.green }}
            onClick={() =>
              handleConfirm({
                id: item,
                isAccepted: false,
                setIsDisabled: setIsDisabled,
                isDisabled: isDisabled,
              })
            }
            disabled={isDisabled}
          >
            Short List
          </Button>
        </>
      ) : (
        <Button variant="primary" size="sm" style={buttonStyle.base}>
          Rejected
        </Button>
      )}
    </div>
  );
};

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  const paginationItems = useMemo(() => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  }, [currentPage, totalPages, onPageChange]);

  return (
    <Pagination size="sm" className="mb-2 mb-lg-0">
      <Pagination.Prev
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
      >
        <FontAwesomeIcon icon={faAngleDoubleLeft} />
      </Pagination.Prev>

      {paginationItems.length > MAX_VISIBLE_PAGES ? (
        <>
          {paginationItems.slice(0, 5)}
          <Pagination.Ellipsis />
          {paginationItems.slice(-5)}
        </>
      ) : (
        paginationItems
      )}

      <Pagination.Next
        onClick={() => onPageChange(Math.min(totalPages, currentPage + 1))}
        disabled={currentPage === totalPages}
      >
        <FontAwesomeIcon icon={faAngleDoubleRight} />
      </Pagination.Next>
    </Pagination>
  );
};

const Applicants = ({ id }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [error, setError] = useState(null);

  const { pathname } = useLocation();
  const jobId = pathname.split("/")[2];

  const applicants = useSelector(
    (state) => state?.addJob?.Applicants?.data?.jobs
  );
  const pagination = useSelector((state) => state?.addJob?.Applicants?.data);

  useEffect(() => {
    if (id === "Applied") {
      setLoader(true);
      try {
        dispatch(
          getApplicants({
            id: jobId,
            page,
            limit: ITEMS_PER_PAGE,
            setLoader,
          })
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoader(false);
      }
    }
  }, [dispatch, id, jobId, page]);

  const handleConfirm = async (data) => {
    data.setIsDisabled(true);
    try {
      const action = data.isAccepted
        ? getConfirmApplicants
        : shortListApplicant;
      const payload = {
        userId: data.isAccepted ? data.id.id : data.id.user.id,
        id: jobId,
        setLoader,
        setIsDisabled: data.setIsDisabled,
        ...(data.isAccepted && {
          isAccepted: data.isAccepted,
          page,
          limit: ITEMS_PER_PAGE,
        }),
      };

      await dispatch(action(payload));
    } catch (err) {
      setError(err.message);
    } finally {
      data.setIsDisabled(true);
      setLoader(false);
    }
  };

  if (error) return <div className="error-message">{error}</div>;
  if (loader) return <Spinner />;
  if (!applicants?.length) return <NoRecordFound />;

  return (
    <ErrorBoundary>
      <div>
        <Row className="py-2 justify-content-between">
          <Col lg={12} md={12} sm={12} xs={12} className="pb-3">
            {applicants.map((item, index) => (
              <ApplicantCard
                key={`applicant-${index}`}
                item={item}
                handleConfirm={handleConfirm}
                handleRating={() => {}}
              />
            ))}
          </Col>

          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
            <Nav>
              <PaginationControls
                currentPage={page}
                totalPages={pagination?.pages || 1}
                onPageChange={setPage}
              />
            </Nav>
            <small className="fw-bold">
              Showing <b>{pagination?.jobs?.length}</b> out of{" "}
              <b>{pagination?.total_applicants}</b> entries
            </small>
          </Card.Footer>
        </Row>
      </div>
    </ErrorBoundary>
  );
};

Applicants.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Applicants;
