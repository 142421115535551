import { toast } from "react-toastify";
import { all, fork, put, select, takeLatest } from "redux-saga/effects";
import axios from "../../Routes/axiosConfig";
import { sagaErrorHandler } from "../../Shared/shared";
import { makeSelectAuthToken } from "../../Store/selector";
import {
  getProfileSuccess,
  reportListSuccess,
  hiredApplicantSuccess,
  getReviewsSuccess,
} from "./actions";
import { getList } from "../chat/actions";
import {
  GET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_CUSTOMER_PROFILE,
  UPDATE_WORKER_PROFILE,
  UPDATE_COMPLETE_WORKER_PROFILE,
  BLOCK_USER,
  REPORT_USER_LIST,
  REPORTED_USER,
  UNBLOCK_USER,
  HIRED_APPLICANTS,
  GET_REVIEWS,
} from "./constants";
// import { CapitalizeFirstLetter } from "../../utils/Global";
import { logoutRequestSuccess } from "../auth/actions";
import { logoutRequest } from "../auth/actions";
import { jobById } from "../addJob/actions";

function* getProfileById({ payload }) {
  try {
    payload.setIsLoading && payload.setIsLoading(true);
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`profile/${payload.profileId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // payload.setLoader(false);
    payload.setIsLoading && payload.setIsLoading(false);
    yield put(getProfileSuccess(response.data.data));
    if (payload.id !== undefined) {
      yield put(jobById(payload));
    }
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(logoutRequest());
    }
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetProfile() {
  yield takeLatest(GET_PROFILE, getProfileById);
}
function* updateAdminProfileSaga({ payload }) {
  let Url = payload.admin ? `profile/update/admin` : "profile/update/customer";
  let Data = new FormData();
  if (payload.admin) {
    Data.append("fullName", payload.fullName ? payload.fullName : null);
    Data.append("profileImg", payload.profileImg ? payload.profileImg : null);
  } else {
    Data.append(
      "businessName",
      payload.businessName ? payload.businessName : null
    );
    Data.append("fullName", payload.fullName ? payload.fullName : null);
    Data.append("address", payload.address ? payload.address : null);
    Data.append(
      "dateofBirth",
      payload.dateofBirth ? payload.dateofBirth : null
    );
    Data.append(
      "phoneNumber",
      payload.phoneNumber ? payload.phoneNumber : null
    );
    Data.append("city", payload.city ? payload.city : null);
    Data.append("postalCode", payload.postalCode ? payload.postalCode : null);
    Data.append("id", payload.id ? payload.id : null);
    Data.append("profileImg", payload.profileImg ? payload.profileImg : null);
    Data.append(
      "industryType",
      payload.industryType ? payload.industryType : null
    );
    // Data.append("email", payload.email ? payload.email : null);
    // Data.append("province", payload.province ? payload.province : null);
    // Data.append("country", payload.country ? payload.country : null);
    Data.append("latitude", payload.latitude ? payload.latitude : null);
    Data.append("longitude", payload.longitude ? payload.longitude : null);
    // Data.append(
    //   "category",
    //   payload.category ? JSON.stringify(payload.category) : null
    // );
    // Data.append(
    //   "isBusinessProfile",
    //   payload.isBusinessProfile ? payload.isBusinessProfile : null
    // );
    // Data.append(
    //   "businessLicense",
    //   payload.businessLicense ? payload.businessLicense : null
    // );
    Data.append("videoUrl", payload.videoURL ? payload.videoURL : null);
    Data.append(
      "languages",
      payload.languages ? JSON.stringify(payload.languages) : null
    );
  }
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(Url, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    toast.success(response.data.message);
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchUpdateAdminProfile() {
  yield takeLatest(UPDATE_PROFILE, updateAdminProfileSaga);
}
function* updateCustomerProfileSaga({ payload }) {
  let Data = new FormData();
  Data.append("fullName", payload.fullName ? payload.fullName : null);
  Data.append("address", payload.address ? payload.address : null);
  Data.append("dateofBirth", payload.dateofBirth ? payload.dateofBirth : null);
  Data.append("phoneNumber", payload.phoneNumber ? payload.phoneNumber : null);
  Data.append("city", payload.city ? payload.city : null);
  Data.append("postalCode", payload.postalCode ? payload.postalCode : null);
  Data.append("id", payload.id ? payload.id : null);
  Data.append("profileImg", payload.profileImg ? payload.profileImg : null);
  Data.append("latitude", payload.latitude ? payload.latitude : null);
  Data.append("longitude", payload.longitude ? payload.longitude : null);
  Data.append("accountType", payload.accountType ? payload.accountType : null);
  Data.append(
    "category",
    payload.category ? JSON.stringify(payload.category) : null
  );
  Data.append(
    "isBusinessProfile",
    payload.isBusinessProfile ? payload.isBusinessProfile : null
  );
  Data.append(
    "businessLicense",
    payload.businessLicense ? payload.businessLicense : null
  );
  Data.append("videoUrl", payload.videoURL ? payload.videoURL : []);
  Data.append(
    "languages",
    payload.languages ? JSON.stringify(payload.languages) : null
  );
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`profile/customer`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    toast.success(response.data.message);
    yield put(logoutRequestSuccess());
    // response.data.status === 201 && payload.history("/");
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchUpdateCustomerProfile() {
  yield takeLatest(UPDATE_CUSTOMER_PROFILE, updateCustomerProfileSaga);
}
function* updateWorkerProfileSaga({ payload }) {
  let Data = new FormData();
  Data.append("fullName", payload.fullName ? payload.fullName : null);
  Data.append("address", payload.address ? payload.address : null);
  Data.append("dateofBirth", payload.dateofBirth ? payload.dateofBirth : null);
  Data.append("phoneNumber", payload.phoneNumber ? payload.phoneNumber : null);
  Data.append("city", payload.city ? payload.city : null);
  Data.append("postalCode", payload.postalCode ? payload.postalCode : null);
  Data.append("id", payload.id ? payload.id : null);
  Data.append("profileType", payload.profileType ? payload.profileType : null);
  Data.append("profileImg", payload.profileImg ? payload.profileImg : null);
  Data.append("accountType", payload.accountType ? payload.accountType : null);
  Data.append("province", payload.province ? payload.province : null);
  Data.append(
    "languages",
    payload.languages ? JSON.stringify(payload.languages) : null
  );
  Data.append(
    "transportationAvailable",
    payload.transportationAvailable ? payload.transportationAvailable : null
  );
  Data.append("frontID", payload.frontID ? payload.frontID : null);
  Data.append("backID", payload.backID ? payload.backID : null);
  Data.append("videoUrl", payload.videoURL ? payload.videoURL : []);
  Data.append(
    "category",
    payload.category ? JSON.stringify(payload.category) : null
  );
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`profile/worker`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    toast.success(response.data.message);
    yield put(logoutRequestSuccess());
    response.data.status === 201 && payload.history("/");
    payload.setLoader(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchUpdateWorkerProfile() {
  yield takeLatest(UPDATE_WORKER_PROFILE, updateWorkerProfileSaga);
}
function* updateCompleteWorkerProfileSaga({ payload }) {
  let Data = new FormData();
  Data.append("fullName", payload.fullName ? payload.fullName : null);
  Data.append("address", payload.address ? payload.address : null);
  Data.append("dateofBirth", payload.dateofBirth ? payload.dateofBirth : null);
  Data.append("phoneNumber", payload.phoneNumber ? payload.phoneNumber : null);
  Data.append("city", payload.city ? payload.city : null);
  Data.append("postalCode", payload.postalCode ? payload.postalCode : null);
  Data.append("id", payload.id ? payload.id : null);
  Data.append("profileType", payload.profileType ? payload.profileType : null);
  Data.append("profileImg", payload.profileImg ? payload.profileImg : null);
  Data.append("accountType", payload.accountType ? payload.accountType : null);
  Data.append("province", payload.province ? payload.province : null);
  Data.append("latitude", payload.latitude ? payload.latitude : null);
  Data.append("longitude", payload.longitude ? payload.longitude : null);
  Data.append(
    "languages",
    payload.languages ? JSON.stringify(payload.languages) : null
  );
  Data.append(
    "transportationAvailable",
    payload.transportationAvailable ? payload.transportationAvailable : null
  );
  Data.append(
    "socailInsuranceInfo",
    payload.socailInsuranceInfo ? payload.socailInsuranceInfo : null
  );
  Data.append(
    "certification",
    payload.certification ? payload.certification : null
  );
  Data.append("license", payload.license ? payload.license : null);
  Data.append(
    "workExperiences",
    payload.workExperience ? JSON.stringify(payload.workExperience) : []
  );
  Data.append(
    "achievements",
    payload.achievements ? JSON.stringify(payload.achievements) : []
  );
  Data.append("videoUrl", payload.videoURL ? payload.videoURL : null);
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.patch(`profile/update/worker`, Data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });

    toast.success(response.data.message);
    // yield put(logoutRequestSuccess());
    // response.data.status === 200 && payload.history("/");
    payload.setLoader(false);
  } catch (error) {
    payload.setLoader(false);
    yield sagaErrorHandler(error.response);
  }
}
function* watchUpdateCompleteWorkerProfile() {
  yield takeLatest(
    UPDATE_COMPLETE_WORKER_PROFILE,
    updateCompleteWorkerProfileSaga
  );
}
function* BlockUserSaga({ payload }) {
  try {
    let data = {
      blockedTo: payload.blockedTo,
      blockedBy: payload.blockedBy,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`blocked-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(response.data.message);
    payload.setBlockedBy(true);
    yield put(getList(payload.blockedBy));
    payload.setBlockUserSaga(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchBlockUser() {
  yield takeLatest(BLOCK_USER, BlockUserSaga);
}
function* UnblockUserSaga({ payload }) {
  try {
    let data = {
      blockedTo: payload.blockedTo,
      blockedBy: payload.blockedBy,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`blocked-user/unblock`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(response.data.message);
    payload.setBlockedBy(null);
    yield put(getList(payload.blockedBy));
    payload.setBlockUserSaga(false);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchUnBlockUser() {
  yield takeLatest(UNBLOCK_USER, UnblockUserSaga);
}
function* getReportUser({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`reported-user/reports`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    yield put(reportListSuccess(response.data.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(logoutRequest());
    }
    yield sagaErrorHandler(error.response);
  }
}
function* watchReportUser() {
  yield takeLatest(REPORT_USER_LIST, getReportUser);
}
function* reportedSaga({ payload }) {
  try {
    let data = {
      reportedTo: payload.reportedTo,
      reportedBy: payload.reportedBy,
      description: payload.description,
      reportId: payload.reportId,
    };
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`reported-user/reports`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    toast.success(response.data.message);
    // yield put(reportedUserSuccess(response.data.data.user));
    payload.setSelectedCategory(null);
    payload.setCategories(null);
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchReported() {
  yield takeLatest(REPORTED_USER, reportedSaga);
}
function* hiredJobSaga({ payload }) {
  const { job, setLoader } = payload;
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.post(`job/hiredbyCustomer`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (payload.job !== undefined) {
      yield put(jobById({ id: job, setLoader: setLoader }));
    }
    toast.success(response.data.message);
    yield put(hiredApplicantSuccess(response.data.data));
  } catch (error) {
    yield sagaErrorHandler(error.response);
  }
}
function* watchHiredJob() {
  yield takeLatest(HIRED_APPLICANTS, hiredJobSaga);
}
function* getReviewsById({ payload }) {
  try {
    const token = yield select(makeSelectAuthToken());
    const response = yield axios.get(`job/user/Reviews/${payload.userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // payload.setLoader(false);
    yield put(getReviewsSuccess(response.data.data));
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put(logoutRequest());
    }
    yield sagaErrorHandler(error.response);
  }
}
function* watchGetReviews() {
  yield takeLatest(GET_REVIEWS, getReviewsById);
}
export default function* ProfileSaga() {
  yield all([fork(watchGetProfile)]);
  yield all([fork(watchBlockUser)]);
  yield all([fork(watchUnBlockUser)]);
  yield all([fork(watchUpdateAdminProfile)]);
  yield all([fork(watchUpdateCustomerProfile)]);
  yield all([fork(watchUpdateWorkerProfile)]);
  yield all([fork(watchUpdateCompleteWorkerProfile)]);
  yield all([fork(watchReportUser)]);
  yield all([fork(watchReported)]);
  yield all([fork(watchHiredJob)]);
  yield all([fork(watchGetReviews)]);
}
