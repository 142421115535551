import {
  collection,
  query,
  where,
  doc,
  updateDoc,
  getDocs,
  addDoc,
  setDoc,
  deleteDoc,
  onSnapshot,
  orderBy,
} from "firebase/firestore";
import createChatId from "./CreateChatId";
import { db } from "../../firebase";
import moment from "moment";

export const fetchMessages = async (users, currentUser) => {
  const chatId = createChatId(users);
  const q = query(collection(db, "chats"), where("chatId", "==", chatId));
  onSnapshot(q, { includeMetadataChanges: true }, (res) => {
    if (res !== null && res.docs.length !== 0) {
      const chatRef = collection(db, "chats", chatId, "messages");
      onSnapshot(
        query(chatRef, orderBy("createdAt", "asc")),
        { includeMetadataChanges: true },
        (res) => {
          let msgs = [];
          if (res != null) {
            res.docs.forEach((msg) => {
              if (msg) {
                const {
                  text,
                  createdAt,
                  user: { name, _id, avatar },
                  file,
                  visibleTo,
                } = msg.data();

                let data = {
                  text,
                  docId: msg.id,
                  createdAt: createdAt,
                  visibleTo,
                  user: { name, _id, avatar },
                  file,
                };
                if (visibleTo.includes(currentUser.id)) {
                  msgs.push(data);
                }
              }
            });
            return msgs;
          } else {
            return [];
          }
        }
      );
    } else {
      return [];
    }
  });
};

export const deleteMessage = async (docId, users) => {
  // Generate the chatId using the createChatId function
  const chatId = createChatId(users);

  // Construct the Firestore collection reference
  const messagesCollectionRef = collection(db, "chats", chatId, "messages");

  try {
    // Delete the specific document (message)
    await deleteDoc(doc(messagesCollectionRef, docId));
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error; // Re-throwing the error to handle it in the calling function
  }
};

export const sendMessage = async (
  message,
  jobOffer,
  attachment,
  users,
  currentUser,
  customKey,
  zoom
) => {
  let newMessage = {
    text: message ? message : "",
    attachment: attachment ? attachment : "",
    createdAt: moment().format(),
    customKey: customKey,
    zoom: zoom,
    jobOffer: jobOffer ? jobOffer : {},
    user: {
      _id: currentUser.id,
      name: currentUser.fullName,
      avatar: currentUser.profileImg,
    },
  };
  let exists = false;
  const chatId = createChatId(users);
  const q = query(collection(db, "chats"), where("chatId", "==", chatId));
  const chatSnapshot = await getDocs(q);
  chatSnapshot.forEach(async (document) => {
    if (document.exists) {
      exists = true;
      await updateDoc(doc(db, "chats", chatId), {
        lastmessage: message,
        visibleTo: users,
      });
      await addDoc(collection(db, "chats", chatId, "messages"), newMessage);
      // setMessage("")
    }
  });
  if (!exists) {
    startNewChat(newMessage, chatId);
  }
};

export const startNewChat = async (message, chatId) => {
  await setDoc(doc(db, "chats", chatId), {
    createdAt: new Date(),
    chatId: chatId,
    lastmessage: message.text,
  });
  await addDoc(collection(db, "chats", chatId, "messages"), message);
};

export const updateCustomOffer = async (id, users, jobOffer) => {
  const chatId = createChatId(users);
  var docRef = collection(db, "chats", chatId, "messages");
  await updateDoc(doc(docRef, id), {
    jobOffer: jobOffer,
  });
  window.location.reload();
};
